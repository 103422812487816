import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import DocumentControlPanel from './DocumentControlPanel/DocumentControlPanel';
import { SourceDocument } from '../../types';
import { SMALL_PDF_SIZE_THRESHOLD } from './consts';
import PDFDocument from './PDFDocument';
import PreviewBanner from './PreviewBanner';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
interface PDFViewerProps {
    sourceDocument: SourceDocument;
    setIsDocPreparingToLoad: Function;
}

const PDFViewer: FunctionComponent<PDFViewerProps> = ({
    sourceDocument,
    setIsDocPreparingToLoad
}) => {
    const [numPages, setNumPages] = useState<number>();
    const [currentPageNum, setCurrentPageNum] = useState<number>();
    const [pagesInViewport, setPagesInViewport] = useState<number[]>([]);
    const [pagesInViewportSet, setPagesInViewportSet] = useState<Set<number>>(
        new Set()
    );
    const [goToPage, setGoToPage] = useState<number>();
    const [scale, setScale] = useState<number>(1.0);
    const [isFullDocRendered, setIsFullDocRendered] = useState(false);
    const [isPreviewLoaded, setIsPreviewLoaded] = useState(false);
    const ref = useRef();
    const [fullDocSource, setFullDocSource] = useState<string>('');
    const [previewSource, setPreviewSource] = useState<string>('');
    const [showFullDoc, setShowFullDoc] = useState<boolean>(true);
    const [percentLoaded, setPercentLoaded] = useState<number>(0);
    const [scrolledToSourcePage, setScrolledToSourcePage] =
        useState<boolean>(false);
    const [pageRotateStepsMap, setPageRotateStepsMap] = useState<object>({});
    const [isFullDocError, setIsFullDocError] = useState<boolean>(false);

    const isPreviewError = useRef<boolean>(false);

    const onFullDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const onPageRenderSuccess = (pageNumber: number) => {
        if (pageNumber === sourceDocument.metadata.page && !isFullDocRendered) {
            setCurrentPageNum(sourceDocument.metadata.page);
            setGoToPage(sourceDocument.metadata.page);
            setShowFullDoc(true);
            setTimeout(() => {
                setIsFullDocRendered(true);
            }, 10);
        }
    };

    const onPreviewLoadSuccess = () => {
        setCurrentPageNum(1);
        setIsPreviewLoaded(true);
    };

    const onLoadProgress = ({ loaded, total }) => {
        setIsDocPreparingToLoad(false);
        if (
            previewSource &&
            !isPreviewError.current &&
            total > SMALL_PDF_SIZE_THRESHOLD * 1000
        ) {
            setShowFullDoc(false);
        }
        setPercentLoaded(Math.trunc((loaded / total) * 100));
    };

    const onPreviewError = (error: any, errorType = '') => {
        isPreviewError.current = true;
        setShowFullDoc(true);
        console.error('Preview error: ' + errorType, error);
    };

    const onFullDocError = (error: any, errorType = '') => {
        console.error('Full document error: ' + errorType, error);
        setIsDocPreparingToLoad(false);
        setIsFullDocError(true);
    };

    useEffect(() => {
        if (
            sourceDocument.metadata.source === fullDocSource &&
            !isFullDocError
        ) {
            if (isFullDocRendered) {
                setGoToPage(sourceDocument.metadata.page);
            } else {
                setShowFullDoc(false);
                setPreviewSource(
                    sourceDocument.metadata.source_pdf_preview_url
                );
            }
        } else {
            setFullDocSource(sourceDocument.metadata.source);
            setPreviewSource(sourceDocument.metadata.source_pdf_preview_url);
            setIsFullDocRendered(false);
            setIsPreviewLoaded(false);
            setPagesInViewport([]);
            setNumPages(null);
            setCurrentPageNum(null);
            setGoToPage(null);
            setShowFullDoc(true);
            setPercentLoaded(0);
            setPagesInViewportSet(new Set());
            setPageRotateStepsMap({});
            isPreviewError.current = false;
            setIsDocPreparingToLoad(true);
            setIsFullDocError(false);
        }
        setScale(1.0);
    }, [sourceDocument]);

    const onPageInViewPort = (inViewPort: boolean, pageNum: number) => {
        if (
            !isFullDocRendered ||
            (!inViewPort && !pagesInViewportSet.has(pageNum))
        ) {
            return;
        }

        let newPagesInViewport = [];
        if (inViewPort) {
            if (pageNum < currentPageNum) {
                if (currentPageNum - pageNum > 1) {
                    // Fixes wrong prev page num
                    newPagesInViewport = [pageNum, ...pagesInViewport.slice(1)];
                } else {
                    newPagesInViewport = [pageNum, ...pagesInViewport];
                }
            } else {
                if (pageNum - currentPageNum > 3) {
                    // Fixes wrong prev page num
                    newPagesInViewport = [pageNum, ...pagesInViewport.slice(1)];
                } else {
                    newPagesInViewport = [...pagesInViewport, pageNum];
                }
            }
        } else {
            newPagesInViewport = pagesInViewport.filter(
                (page) => page !== pageNum
            );
        }
        if (newPagesInViewport[0]) {
            setCurrentPageNum(newPagesInViewport[0]);
        }
        setPagesInViewportSet(new Set(newPagesInViewport));
        setPagesInViewport(newPagesInViewport);
    };

    const onScrollIntoView = () => {
        setGoToPage(null);
        if (!scrolledToSourcePage) {
            setTimeout(() => {
                setScrolledToSourcePage(true);
            }, 100);
        }
    };

    const onRotate = (stepsToAdd: number) => {
        setPageRotateStepsMap({
            ...pageRotateStepsMap,
            [currentPageNum]:
                (pageRotateStepsMap[currentPageNum] || 0) + stepsToAdd
        });
    };

    return (
        <div className="pdf-viewer">
            <PreviewBanner
                show={fullDocSource && !isFullDocRendered && !isFullDocError}
                percentLoaded={percentLoaded}
                isPreviewLoaded={isPreviewLoaded}
            />
            <div className="pdf-viewer__document-container" ref={ref}>
                {!showFullDoc && (
                    <PDFDocument
                        sourcePath={previewSource}
                        sourceName={sourceDocument.metadata.filename}
                        showDoc={!showFullDoc}
                        errorHandler={onPreviewError}
                        numPages={1}
                        contentToHighlight={sourceDocument.content}
                        onLoadSuccess={onPreviewLoadSuccess}
                        dataTestId="pdf-document-preview"
                    />
                )}
                <PDFDocument
                    sourcePath={fullDocSource}
                    sourceName={sourceDocument.metadata.filename}
                    showDoc={showFullDoc}
                    errorHandler={onFullDocError}
                    numPages={numPages}
                    goToPage={goToPage}
                    scale={scale}
                    onPageInViewPort={onPageInViewPort}
                    onScrollIntoView={onScrollIntoView}
                    onPageRenderSuccess={onPageRenderSuccess}
                    showPages={scrolledToSourcePage}
                    onLoadSuccess={onFullDocumentLoadSuccess}
                    onLoadProgress={onLoadProgress}
                    isDocRendered={isFullDocRendered}
                    parentElement={ref.current}
                    contentToHighlight={sourceDocument.content}
                    pageNumToHighlight={sourceDocument.metadata.page}
                    pageRotateStepsMap={pageRotateStepsMap}
                    dataTestId="pdf-document-full"
                />
            </div>
            {isFullDocRendered && (
                <DocumentControlPanel
                    pageNum={currentPageNum}
                    scale={scale}
                    setScale={setScale}
                    numPages={numPages}
                    setPageNum={setGoToPage}
                    pagesInViewport={pagesInViewport}
                    onRotate={onRotate}
                />
            )}
        </div>
    );
};

export default PDFViewer;
