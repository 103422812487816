import {
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import FilterSelect from '../FilterSelect/FilterSelect';
import { useIntegratedModels, useModel } from '../../AppContext';
import { Trans, useTranslation } from 'react-i18next';
import { getModelNameToObject } from '../../utils/productUtils';
import {
    ANALYTICS_EVENTS,
    MAX_MODELS_IN_PICKLIST,
    SHOW_MODEL_SEARCH_THRESHOLD
} from '../../consts';
import { IntegratedModel } from '../../types';
import { PopoverOrigin, Tooltip } from '@mui/material';
import SearchSelect from '../SearchSelect/SearchSelect';
import useAnalytics from '../../hooks/useAnalytics';
import { getFilteredModelsSortedByPriority } from './modelSelectUtils';
import SerialNumberInput from '../SerialNumberInput/SerialNumberInput';
import styles from './ModelSelect.module.scss';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

/**
 * ingestedModels - list of model names that have ingested source files (from scop db)
 * integratedModels - list of products with product hierarchy from Triage (producttype, manufacturer, model)
 * adjustedModels - list of strings introducing full product hierarchy
 */

type ModelSelectProps = {
    disabled?: boolean;
    selectedValue?: string;
    dataTestId?: string;
    selectLabel?: string;
    setSelectedValue: (filter: IntegratedModel) => void;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    className?: string;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    onClick?: () => void;
    isSearchSelect?: boolean;
    searchPlaceholder?: string;
    prioritizedIntegratedModels?: IntegratedModel[];
    autoSelect?: boolean;
};

const ModelSelect: FunctionComponent<ModelSelectProps> = ({
    disabled = false,
    selectedValue = '',
    dataTestId = 'select-model',
    selectLabel = 'model',
    setSelectedValue,
    isOpen,
    setIsOpen,
    prioritizedIntegratedModels = [],
    className = '',
    anchorOrigin,
    transformOrigin,
    isSearchSelect = false,
    searchPlaceholder = '',
    onClick,
    autoSelect
}) => {
    const { t } = useTranslation();
    const { sendEvent } = useAnalytics();
    const { integratedModels, isFetchingIntegratedModels, ingestedModels } =
        useIntegratedModels();
    const {
        isRangeEnabled,
        isModelIntegrationError,
        setIsModelIntegrationError
    } = useModel();
    const [searchText, setSearchText] = useState('');
    const modelNameToObject = useMemo(
        () => getModelNameToObject(integratedModels),
        [integratedModels]
    );

    useEffect(() => {
        if (isModelIntegrationError) {
            setTimeout(() => setIsModelIntegrationError(false), 5000)
        }
    }, [isModelIntegrationError, setIsModelIntegrationError]);

    useEffect(() => {
        //If range input needed, do not set any value
        if (isRangeEnabled) {
            return;
        }
        //Selects the model if only one is available
        if (
            autoSelect &&
            integratedModels.length === 1 &&
            (!selectedValue || selectedValue === t('common.all'))
        ) {
            setSelectedValue(integratedModels[0]);
        }
    }, [integratedModels, autoSelect]);

    const prioritizedIntegratedModelsSet = useMemo(
        () => new Set(prioritizedIntegratedModels),
        [prioritizedIntegratedModels]
    );

    useEffect(() => {
        setSearchText('');
    }, [selectedValue]);

    const onModelSelect = (modelSting: string) => {
        setSelectedValue(modelNameToObject[modelSting]);
        if (modelSting !== selectedValue) {
            sendEvent(ANALYTICS_EVENTS.SELECT_DIFFERENT_MODEL_CLICK, {
                'Model value': modelSting
            });
        }
    };

    const getFilteredModels = useCallback(() => {
        return getFilteredModelsSortedByPriority(
            prioritizedIntegratedModelsSet,
            integratedModels,
            searchText
        );
    }, [integratedModels, searchText, prioritizedIntegratedModelsSet]);

    const getTooltip = () => {
        return (
            <div>
                {isRangeEnabled ? (
                    <Trans
                        i18nKey="integration-error.serial-number"
                        components={{
                            bold: (
                                <span
                                    className={cx(
                                        'model-select__tooltip__bold'
                                    )}
                                />
                            ),
                            br: <br />
                        }}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    };

    const onSetIsOpen = (isOpen: boolean) => {
        if (isModelIntegrationError) {
            setIsModelIntegrationError(false);
        }
        setIsOpen(isOpen);
    };

    return (
        <Tooltip
            title={getTooltip()}
            open={isModelIntegrationError ?? false}
            placement="top"
            classes={{
                tooltip: cx('model-select__tooltip'),
                arrow: cx('model-select__tooltip-arrow')
            }}
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 20]
                            }
                        }
                    ]
                }
            }}
            arrow
        >
            {isRangeEnabled ? (
                <SerialNumberInput
                    onSetModel={onModelSelect}
                    isOpen={isOpen}
                    setIsOpen={onSetIsOpen}
                    processing={isFetchingIntegratedModels}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                />
            ) : isSearchSelect ? (
                <SearchSelect
                    values={getFilteredModels()}
                    setValue={onModelSelect}
                    value={selectedValue}
                    className={className}
                    disabled={disabled}
                    isOpen={isOpen}
                    setIsOpen={onSetIsOpen}
                    dataTestId={`${dataTestId}_select`}
                    showFooterMessage={
                        ingestedModels.length > MAX_MODELS_IN_PICKLIST
                    }
                    footerMessageText={`${MAX_MODELS_IN_PICKLIST}+ ${t(
                        'model.limit-shown-models'
                    )}`}
                    searchPlaceholder={
                        searchPlaceholder || t('model.search-placeholder')
                    }
                    searchText={searchText}
                    setSearchText={setSearchText}
                    processing={isFetchingIntegratedModels}
                    onClick={onClick}
                    enableFlip
                    noValuesMessage={t('model.model-not-found')}
                />
            ) : (
                <FilterSelect
                    headerText={selectLabel}
                    filters={getFilteredModels()}
                    setFilter={onModelSelect}
                    filter={selectedValue}
                    className={className}
                    disabled={disabled}
                    isOpen={isOpen}
                    setIsOpen={onSetIsOpen}
                    dataTestId={`${dataTestId}_select`}
                    showFooterMessage={
                        ingestedModels.length > MAX_MODELS_IN_PICKLIST
                    }
                    footerMessageText={`${MAX_MODELS_IN_PICKLIST}+ ${t(
                        'model.limit-shown-models'
                    )}`}
                    withSearch={
                        integratedModels.length > SHOW_MODEL_SEARCH_THRESHOLD
                    }
                    searchPlaceholder={
                        searchPlaceholder || t('model.search-placeholder')
                    }
                    searchText={searchText}
                    setSearchText={setSearchText}
                    inProgress={isFetchingIntegratedModels}
                    selectLabel={selectLabel}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    onClick={onClick}
                    noValuesMessage={t('model.model-not-found')}
                />
            )}
        </Tooltip>
    );
};

export default ModelSelect;
