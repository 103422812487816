import { FunctionComponent, useEffect, useState } from 'react';
import { Typography } from 'aqui';
import { TrashIcon } from 'aqui/icons';
import SparklesMedium from '../../SVG/SparklesMedium';
import SmallAquantLogo from '../../SVG/SmallAquantLogo';
import { Tooltip } from '@mui/material';
import Backend from '../../backend/Backend';
import {
    useIntegratedModels,
    useProductDetails,
    useStartNewSession,
    useThreadsHandling,
    useModel
} from '../../AppContext';
import { SortingType } from '../../types';
import { prepareDate } from '../../utils/utils';
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';
import { useDispatch, useSelector } from 'react-redux';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../consts';
import { resetWorkOrderState } from '../../actions/triageActions';
import {
    useAskStream,
    useAskStreamContext
} from '../../context/AskStreamContext';
import { t } from 'i18next';

type InnerThreadProps = {
    index: number;
    isSelected: boolean;
    onClickThread: (
        chatId: string,
        model: string,
        serialNumber: string | null,
        investigationId: string,
        goToTriage?: boolean
    ) => void;
    question: string;
    model?: string;
    serialNumber?: string;
    date: string;
    investigationId: string | null;
    chatId: string;
    modelIndex?: number;
    selectedIndex: number;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
    productId: string;
    chatSummary: string | null;
};

const InnerThread: FunctionComponent<InnerThreadProps> = ({
    index,
    isSelected,
    onClickThread,
    question,
    model = '',
    serialNumber = '',
    date,
    investigationId,
    chatId,
    modelIndex = null,
    selectedIndex,
    setSelectedIndex,
    productId,
    chatSummary
}) => {
    const [isDeleted, setIsDeleted] = useState(false);
    const [updatedDate, setUpdatedDate] = useState(null);
    const {
        chatHistoryData,
        setChatHistoryData,
        threadsSortingType,
        setFirstThreadQuestion,
        setIsChatHistoryLoaded
    } = useThreadsHandling();
    const { isStreamInProgress, cancelStream } = useAskStreamContext();
    const { startNewSession } = useStartNewSession();
    const { setProductDetails } = useProductDetails();
    const { integratedModels } = useIntegratedModels();
    const { isRangeEnabled } = useModel();
    const { sendEvent } = useAnalytics();
    const dispatch = useDispatch();

    const selctedChatId = useSelector(
        (state: any) => state.coPilotReducer.chatId
    );

    useEffect(() => {
        const today = new Date().setHours(0, 0, 0, 0);
        const threadsDay = new Date(date).setHours(0, 0, 0, 0);

        if (
            isStreamInProgress &&
            chatId === selctedChatId &&
            threadsDay !== today
        ) {
            setUpdatedDate(prepareDate(today));
        }
    }, [isStreamInProgress]);

    const onClickDelete = async (e) => {
        e.stopPropagation();
        cancelStream();
        setIsDeleted(true);
        await Backend.archiveChat(chatId);
        if (threadsSortingType === SortingType.Date) {
            setChatHistoryData((prev) => {
                return [
                    {
                        data: chatHistoryData[0].data.filter(
                            (obj) => obj.chat_id !== chatId
                        ),
                        tenant_model: null,
                        has_more: prev[0].has_more
                    }
                ];
            });
        } else {
            const modelWithoutDeletedChat = chatHistoryData[
                modelIndex
            ].data.filter((obj) => obj.chat_id !== chatId);
            const newChatHistoryData = chatHistoryData;
            newChatHistoryData[modelIndex].data = modelWithoutDeletedChat;
            setChatHistoryData([...newChatHistoryData]);
        }
        if (isSelected) {
            startNewSession();
        }
    };

    const onClickInvestigation = (e) => {
        e.stopPropagation();
        sendEvent(ANALYTICS_EVENTS.CLICKED_INVESTIGATION_ON_THREAD);
        setIsChatHistoryLoaded(false);
        onClickThread(chatId, model, serialNumber, investigationId, true);
    };

    const onSelectTread = (e) => {
        if (isSelected) {
            return;
        }
        setIsChatHistoryLoaded(false);
        cancelStream();
        e.stopPropagation();
        dispatch(resetWorkOrderState());
        sendEvent(ANALYTICS_EVENTS.CLICKED_THREAD);
        setSelectedIndex(index);
        setFirstThreadQuestion(question);

        !isDeleted &&
            onClickThread(chatId, model, serialNumber, investigationId);
        const modelDetails = integratedModels.filter((integratedModel) => {
            if (productId) {
                return integratedModel?.productIds?.productId === productId;
            } else {
                return integratedModel?.modelName === model;
            }
        });
        if (modelDetails.length === 1) {
            setProductDetails(modelDetails[0]);
        }
    };

    return (
        <div
            key={chatId}
            className={`flex flex-start innerThread__wrapper ${
                isSelected ? 'selected' : ''
            } ${isDeleted ? 'deleted' : ''}`}
            onClick={onSelectTread}
            data-testid="inner-thread"
            data-selected={isSelected}
        >
            {isDeleted && <div className="innerThread__deleted"></div>}
            <div
                className={`innerThread__selected ${isSelected ? '' : 'hide'}`}
            ></div>
            <div
                className={`flex flex-start innerThread__container ${
                    selectedIndex - 1 === index ? 'no-border' : ''
                }`}
            >
                <SparklesMedium color="currentcolor" />
                <div className="flex flex-col innerThread__data">
                    <ValueWithTooltip
                        index={chatId}
                        title={chatSummary || question}
                        className={'innerThread__header'}
                        type="small1"
                        maxLines={2}
                    />
                    {threadsSortingType === SortingType.Date && (
                        <Typography
                            className="innerThread__model"
                            type="small2"
                        >
                            {isRangeEnabled ? serialNumber : model}
                        </Typography>
                    )}
                    <div className="flex align-center innerThread__date-investigation">
                        <Typography className="innerThread__date" type="small2">
                            {updatedDate ? updatedDate : prepareDate(date)}
                        </Typography>
                        {investigationId && (
                            <div
                                className="flex align-center innerThread__investigation"
                                onClick={onClickInvestigation}
                                data-testid="thread-investigation-button"
                            >
                                <SmallAquantLogo />
                                <Typography
                                    type="small2"
                                    className="innerThread__investigation-text"
                                >
                                    {t('threads.investigation')}
                                </Typography>
                            </div>
                        )}
                    </div>
                </div>
                {!investigationId && (
                    <Tooltip
                        title={t('common.delete') as string}
                        placement="top"
                        classes={{
                            tooltip: 'tooltip tooltip__threadsPanel'
                        }}
                    >
                        <div
                            className="innerThread__trash"
                            onClick={onClickDelete}
                            data-testid="delete-thread-button"
                        >
                            <TrashIcon />
                        </div>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default InnerThread;
