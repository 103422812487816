import { FC, useState } from 'react';
import { getFormattedVideoUrl } from '../../utils/storageUtils';

interface VideoValidatorProps {
    videoUrl: string;
    fileType?: string;
    onValid?: Function;
    onError?: Function;
}

const VideoValidator: FC<VideoValidatorProps> = ({
    videoUrl,
    fileType = 'video/mp4',
    onValid,
    onError
}) => {
    const [videoRef, setVideoRef] = useState<HTMLVideoElement>(null);
    const [isTested, setIsTested] = useState<boolean>(false);
    const formattedVideoUrl = getFormattedVideoUrl(videoUrl);

    const onDurationChange = () => {
        if (!isTested) {
            setIsTested(true);
            onValid?.(videoRef);
        }
    };

    const handleError = () => {
        if (!isTested) {
            setIsTested(true);
            onError?.();
        }
    };

    return (
        <video
            ref={setVideoRef}
            style={{ display: 'none' }}
            onError={handleError}
            muted
            autoPlay={false}
            onDurationChange={onDurationChange}
        >
            <source src={formattedVideoUrl} type={fileType} />
        </video>
    );
};

export default VideoValidator;
