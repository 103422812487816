import { HTMLAttributes, forwardRef, useState } from 'react';
import { Popover, PopoverOrigin } from '@mui/material';
import { Typography } from 'aqui';
import { combineRefs } from '../../utils/componentUtils';
import { ChevronIcon } from 'aqui/icons';
import classnames from 'classnames/bind';
import styles from './SerialNumberInput.module.scss';
import SerialNumberMenu from './SerialNumberMenu';
import { useModel } from '../../AppContext';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
const cx = classnames.bind(styles);

interface SerialNumberInputProps extends HTMLAttributes<HTMLDivElement> {
    disabled?: boolean;
    onSetModel: (model_name: string) => void;
    processing?: boolean;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    isOpen: boolean;
    setIsOpen: Function;
}

const SerialNumberInput = forwardRef<HTMLDivElement, SerialNumberInputProps>(
    (
        {
            disabled,
            onSetModel,
            processing = false,
            anchorOrigin,
            transformOrigin,
            isOpen,
            setIsOpen
        },
        ref
    ) => {
        const [anchorEl, setAnchorEl] = useState<null | Element>(null);
        const { handleNewSerialNumber, modelSerialNumber } = useModel();

        const handleClick = (e: any) => {
            if (disabled || processing) {
                return;
            }
            setIsOpen(!isOpen);
        };

        const closeHandler = () => setIsOpen(false);

        const onSetModelData = (model_name: string, serialNumber: string) => {
            onSetModel(model_name);
            handleNewSerialNumber(serialNumber);
            closeHandler();
        };

        return (
            <div
                className={cx('sn-input', {
                    '--disabled': disabled
                })}
                onClick={handleClick}
                ref={combineRefs(setAnchorEl, ref)}
            >
                {modelSerialNumber ? (
                    <Typography type="small2" className={cx('sn-input__label')}>
                        <span>
                            SN{' '}
                            <span
                                className={cx('sn-input', { '--bold': true })}
                            >
                                {modelSerialNumber}
                            </span>
                        </span>
                    </Typography>
                ) : (
                    <Typography
                        type="small2SemiBold"
                        className={cx('sn-input__label')}
                    >
                        {t('model.serial-number')}
                    </Typography>
                )}

                <ChevronIcon
                    className={cx('sn-input__chevron-icon', {
                        '--flipped': isOpen
                    })}
                    direction="down"
                />
                <Popover
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    anchorEl={anchorEl}
                    open={isOpen}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    onClose={closeHandler}
                    classes={{
                        paper: 'popoverBox__root'
                    }}
                    sx={{
                        '& .MuiPaper-root': {
                            margin: '16px 10px !important'
                        }
                    }}
                >
                    <SerialNumberMenu
                        disabled={disabled || processing}
                        onSetModel={onSetModelData}
                    />
                </Popover>
            </div>
        );
    }
);

export default SerialNumberInput;
