import { FunctionComponent, useEffect, useState } from 'react';
import { TextInput, Button } from 'aqui';
import { CrossMarkIcon, CheckMarkIcon, WarningIcon } from 'aqui/icons';
import Menu from '../Menu/Menu';
import classnames from 'classnames/bind';

import styles from './SerialNumberInput.module.scss';
import Backend from '../../backend/Backend';
import { CircularProgress } from '@mui/material';
import { adjustIntegratedModel } from '../../utils/productUtils';
import { useIntegratedModels } from '../../AppContext';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
const cx = classnames.bind(styles);

interface SerialNumberMenuProps {
    disabled?: boolean;
    onSetModel: Function;
}

const SerialNumberMenu: FunctionComponent<SerialNumberMenuProps> = ({
    disabled,
    onSetModel
}) => {
    const [inputText, setInputText] = useState<string>();
    const [error_message, setErrorMessage] = useState<string>();
    const [isValid, setIsValid] = useState<boolean>(true);
    const [inProgress, setInProgress] = useState<boolean>(false);

    const { integratedModels } = useIntegratedModels();

    useEffect(() => {
        setIsValid(true);
    }, [inputText]);

    const handleSetValue = (input: string) => {
        // todo: replace genie-specific code:
        const regex = /^[a-zA-Z0-9-]*$/;
        if (regex.test(input)) {
            setInputText(input.toUpperCase());
            setErrorMessage(undefined);
        } else setErrorMessage(t('serial-number-input.special-char-error'));
    };

    const inputIsValid = (input: string) => {
        // todo: replace genie-specific code:
        if (input && !input.includes('-')) {
            setErrorMessage(t('serial-number-input.no-dash-error'));
            setIsValid(false);
            return false;
        }
        return true;
    };

    const fetchModel = (input: string) => {
        setInProgress(true);
        Backend.fetchRangedModel(input).then((data) => {
            setInProgress(false);
            if (data.is_valid) {
                const model = integratedModels.find(
                    (model) => model.modelName === data.response
                );
                const modelFullString = adjustIntegratedModel(model);
                onSetModel(modelFullString, input);
            } else {
                setErrorMessage(t('serial-number-input.not-found-error'));
                setIsValid(false);
            }
        });
    };

    const onSubmit = () => {
        if (inputIsValid(inputText)) {
            fetchModel(inputText);
        }
    };

    const onClear = () => {
        setInputText(undefined);
        setErrorMessage(undefined);
    };

    const getExamplesFromChatHistory = () => {
        // todo: replace genie-specific code:
        return ['GS3005A-12345', 'GS30-12345', 'SX180016H-318'];
    };

    const onkeydown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (onSubmit) {
                onSubmit();
            }
        }
    };

    return (
        <Menu className={cx('sn-input__menu')}>
            <div className={cx('sn-input__menu__row')}>
                <TextInput
                    placeholder={'Enter serial number (including dash)'}
                    value={inputText}
                    setValue={handleSetValue}
                    className={cx('sn-input__search')}
                    disabled={disabled}
                    onKeyDown={onkeydown}
                />
                <Button
                    shape="circle"
                    onClick={onClear}
                    disabled={!inputText || disabled || inProgress}
                    IconComponent={CrossMarkIcon}
                />
                <Button
                    shape="circle"
                    onClick={onSubmit}
                    disabled={!inputText || disabled || inProgress || !isValid}
                    variant="filled"
                    IconComponent={CheckMarkIcon}
                />
            </div>
            {error_message && (
                <div
                    className={cx('sn-input__menu__row')}
                    style={{ gap: '4px' }}
                >
                    <WarningIcon className={cx('sn-input__error_icon')} />
                    <div className={cx('sn-input__error_message')}>
                        {error_message}
                    </div>
                </div>
            )}
            <div className={cx('sn-input__menu__row')}>
                <div className={cx('sn-input__message')}>
                    {`${t('serial-number-input.examples')}: ` +
                        getExamplesFromChatHistory().slice(0, 3).join(', ')}
                </div>
            </div>

            {inProgress && (
                <div className={cx('sn-input__menu__progress-overlay')}>
                    <CircularProgress
                        className={cx('sn-input__menu__progress')}
                    />
                </div>
            )}
        </Menu>
    );
};

export default SerialNumberMenu;
