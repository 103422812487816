import './styles/App.scss';
import Home from './Home';
import WebFont from 'webfontloader';

import { RecoilRoot } from 'recoil';
import { AppContextProvider } from './AppContext';
import { ChatContextProvider } from './context/ChatContext';

import mixpanel from 'mixpanel-browser';
import { SentryWrapper } from './classes/SentryWrapper';
import ModalBox from './components/ModalBox/ModalBox';
import { DataManagementContextProvider } from './context/DataManagementContext';
import { LicenseInfo } from '@mui/x-license-pro';
import { AskStreamContextProvider } from './context/AskStreamContext';

WebFont.load({
    google: {
        families: ['Noto Sans:300,400,500,700']
    }
});

window.sentry = new SentryWrapper();
window.sentry.init();
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
mixpanel.identify(window?.__sfdcIds?.userId);

LicenseInfo.setLicenseKey(
    '1e8a43d3ca8d0662dbf13460dfd97560Tz0xMDIxNTAsRT0xNzY1NDUyMTA0MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
);

function App() {
    return (
        <div id="aq-genai-root">
            <RecoilRoot>
                <AppContextProvider>
                    <ChatContextProvider>
                        <AskStreamContextProvider>
                            <DataManagementContextProvider>
                                <ModalBox />
                                <Home />
                            </DataManagementContextProvider>
                        </AskStreamContextProvider>
                    </ChatContextProvider>
                </AppContextProvider>
            </RecoilRoot>
        </div>
    );
}

export default App;
