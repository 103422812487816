import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getSummaryData } from '../../selectors/summarySelector';
import { Bold, Typography } from 'aqui';
import {
    generateCoPilotChatHistory,
    generateDetails
} from '../../utils/summaryUtils';
import SidePanel from '../SidePanel/SidePanel';
import SummaryModalTitle from './SummaryModalTitle';
import ChatSummary from './ChatSummary';
import { RenderValueParams } from './types';
import SummaryModalFooter from './SummaryModalFooter';
import { useModel } from '../../AppContext';

interface SummaryModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const SummaryModal: FC<SummaryModalProps> = ({ isOpen, onClose }) => {
    const { model, manufacturer, productType, scopMessages, feedbacksMap } =
        useSelector(getSummaryData);
    const { modelSerialNumber } = useModel();

    const renderField = ({ value = '', bold = false }) => {
        if (bold) {
            return <Bold>{value}</Bold>;
        }
        return value;
    };

    const renderKeyWithValue = ({
        key,
        value,
        suffix,
        boldKey = false,
        boldValue = false,
        boldSuffix = false
    }: {
        key: string;
        value: string;
        suffix?: string;
        boldKey?: boolean;
        boldValue?: boolean;
        boldSuffix?: boolean;
    }) => {
        return (
            <Typography type="body3" key={key}>
                {!!key &&
                    renderField({
                        value: key,
                        bold: boldKey
                    })}
                {!!value &&
                    renderField({
                        value,
                        bold: boldValue
                    })}
                {!!suffix &&
                    renderField({
                        value: suffix,
                        bold: boldSuffix
                    })}
            </Typography>
        );
    };

    const renderValue = ({
        value,
        bold = false,
        marginBottom = 0
    }: RenderValueParams) => {
        if (value) {
            return (
                <Typography type="body3" key={value} style={{ marginBottom }}>
                    {renderField({ value, bold })}
                </Typography>
            );
        }
        return null;
    };

    const renderHeader = (header: string) => (
        <Typography className="summary-modal__title" type="h6">
            {header}
        </Typography>
    );

    const renderSeparator = (marginBottom = 24) => (
        <div className="summary-modal__separator" style={{ marginBottom }} />
    );

    const renderDetails = () => {
        const { details, header } = generateDetails({
            modelSerialNumber,
            productType,
            manufacturer,
            model
        });
        return (
            <>
                {renderHeader(header)}
                {details.map(({ key, value }) =>
                    renderKeyWithValue({
                        key: key.text,
                        value: value.text,
                        boldKey: key.bold,
                        boldValue: value.bold
                    })
                )}
                {renderSeparator()}
            </>
        );
    };

    const renderCoPilotChatHistory = () => {
        const { header, chatHistory } = generateCoPilotChatHistory(
            scopMessages,
            feedbacksMap
        );
        return (
            <ChatSummary
                scopMessages={chatHistory}
                header={header}
                renderHeader={renderHeader}
                renderValue={renderValue}
                renderSeparator={renderSeparator}
            />
        );
    };

    const generateContent = () => {
        let content = '';
        const { detailsStr } = generateDetails({
            modelSerialNumber,
            productType,
            manufacturer,
            model
        });
        const { coPilotChatHistoryStr } = generateCoPilotChatHistory(
            scopMessages,
            feedbacksMap
        );

        content += detailsStr;
        content += coPilotChatHistoryStr;

        return content.replace(/\\\[\d+\]/g, (match) =>
            match.replace('\\', '')
        );
    };

    const handleExportClick = () => {
        const investigationSummaryContent = generateContent();
        const plainTextBlobObject = new Blob([investigationSummaryContent], {
            type: 'text/plain'
        });
        const docUrl = URL.createObjectURL(plainTextBlobObject);
        const docElement = document.createElement('a');
        docElement.href = docUrl;
        docElement.download = `${new Date().toISOString()}-Summary.txt`;
        docElement.click();
        URL.revokeObjectURL(docUrl);
    };

    return (
        <SidePanel
            open={isOpen}
            onClose={onClose}
            classes={{
                container: 'summary-modal',
                body: 'summary-modal__body',
                footer: 'summary-modal__footer'
            }}
            title={<SummaryModalTitle />}
            footer={<SummaryModalFooter onExportClick={handleExportClick} />}
        >
            <>
                {renderDetails()}
                {renderCoPilotChatHistory()}
            </>
        </SidePanel>
    );
};

export default SummaryModal;
