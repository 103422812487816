import { t } from 'i18next';
import {
    useAllIntegratedModels,
    useDataManagementContext
} from '../../../context/DataManagementContext';
import {
    ExpertResponsesFilters,
    ExpertResponsesRow,
    PaginationModel,
    PopoverRow
} from '../../../types';
import { onExportFile } from '../../../utils/fileUtils';
import { useEffect } from 'react';
import Backend from '../../../backend/Backend';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { useProductDetails, useThreadsHandling } from '../../../AppContext';
import { addIntegratedModelsToResponse } from './utils';
import { adjustIntegratedModel } from '../../../utils/productUtils';
import {
    prepareDate,
    removeMarkdown,
    removeIndicatorsFromText,
    splitTextByIndicators
} from '../../../utils/utils';
import { getAdditionalChatHistoryData } from '../../../utils/backendUtils';
import useAnalytics from '../../../hooks/useAnalytics';
import { useDispatch } from 'react-redux';
import { resetWorkOrderState } from '../../../actions/triageActions';
import { ANALYTICS_EVENTS } from '../../../consts';
import { DEFAULT_ROWS_LIMIT_PER_PAGE } from '../../Table/consts';

export const useExpertResponsesRows = () => {
    const {
        areExpertResponsesFetching,
        expertResponsesRows,
        setExpertResponsesRows,
        expertResponsesEditModalData,
        setExpertResponsesEditModalData,
        setAreExpertResponsesFetching,
        expertResponsesSortModel,
        setExpertResponsesSortModel
    } = useDataManagementContext();

    const updateExpertResponsesRows = (
        rowid: string,
        newResponse: string,
        userName: string,
        activate: boolean,
        vectorId: string = '',
        model: string = ''
    ) => {
        const today = new Date().toISOString();
        const newExpertResponsesRows = expertResponsesRows.map((row) => {
            const isTargetRow = row.id === rowid;
            const shouldDeactivate =
                activate &&
                row.active &&
                row.vector_id === vectorId &&
                row.model === model;

            if (isTargetRow) {
                return {
                    ...row,
                    expert_response: newResponse,
                    user_name: userName,
                    date: today,
                    active: activate || row.active
                };
            } else if (shouldDeactivate) {
                return {
                    ...row,
                    active: false,
                    date: today
                };
            }
            return row;
        });
        setExpertResponsesRows(newExpertResponsesRows);
    };

    const setActiveRows = (
        rowid: string,
        active: boolean,
        vectorId: string,
        model: string
    ) => {
        const today = new Date().toISOString();
        const newExpertResponsesRows = expertResponsesRows.map((row) => {
            const isTargetRow = row.id === rowid;
            const shouldDeactivate =
                row.vector_id === vectorId && row.active && row.model === model;

            if (isTargetRow) {
                return {
                    ...row,
                    active,
                    date: today
                };
            }
            if (shouldDeactivate) {
                return {
                    ...row,
                    active: false,
                    date: today
                };
            }
            return row;
        });
        setExpertResponsesRows(newExpertResponsesRows);
    };

    return {
        areExpertResponsesFetching,
        expertResponsesRows,
        expertResponsesEditModalData,
        setExpertResponsesEditModalData,
        setExpertResponsesRows,
        setAreExpertResponsesFetching,
        updateExpertResponsesRows,
        setActiveRows,
        expertResponsesSortModel,
        setExpertResponsesSortModel
    };
};

export const useExpertResponsesFilters = () => {
    const {
        expertResponsesSearchTerm,
        setExpertResponsesSearchTerm,
        expertResponsesFilters,
        setExpertResponsesFilters
    } = useDataManagementContext();
    const updateFilters = (updaters: ExpertResponsesFilters) => {
        setExpertResponsesFilters((prevFilters: ExpertResponsesFilters) => ({
            ...prevFilters,
            ...updaters
        }));
    };
    const setModelsToFilter = (models: string[]) => {
        updateFilters({ models });
    };
    return {
        expertResponsesSearchTerm,
        setExpertResponsesSearchTerm,
        setModelsToFilter,
        expertResponsesFilters
    };
};

export const useMoreOptionsRowsExpertResponses = () => {
    const { expertResponsesSearchTerm, expertResponsesFilters } =
        useExpertResponsesFilters();
    const { expertResponsesSortModel } = useExpertResponsesRows();
    const { allIntegratedModels } = useAllIntegratedModels();

    const onExport = () => {
        const fileName = `Expert_Responses_${new Date().toISOString()}.csv`;
        const fileData = [];
        const orderBy =
            expertResponsesSortModel[0].field === 'date'
                ? 'updated_at'
                : expertResponsesSortModel[0].field;
        Backend.fetchExpertResponses(
            expertResponsesFilters.models || [],
            expertResponsesSearchTerm,
            expertResponsesSortModel[0].sort,
            orderBy,
            0,
            0
        )
            .then((response) => {
                const newResponse = addIntegratedModelsToResponse(
                    response.expert_responses,
                    allIntegratedModels
                );
                newResponse.forEach((row: ExpertResponsesRow) => {
                    fileData.push({
                        MODEL: row.integratedModel
                            ? adjustIntegratedModel(row.integratedModel)
                            : row.model,
                        'INITIAL QUESTION': row.initial_question,
                        QUESTION: row.question,
                        'ORIGINAL RESPONSE': removeMarkdown(
                            removeIndicatorsFromText(
                                splitTextByIndicators(row.original_response)
                            )
                        ),
                        'EXPERT RESPONSE': removeMarkdown(
                            removeIndicatorsFromText(
                                splitTextByIndicators(row.expert_response)
                            )
                        ),
                        'DISPLAY EDITED RESPONSE': row.active,
                        DATE: prepareDate(row.date),
                        'EXPERT MAIL': row.expert_mail
                    });
                });
                onExportFile(fileData, fileName);
            })
            .catch((ex: any) => {
                console.error('Failed to fetch data.', ex);
                window.sentry.log(ex);
            });
    };

    const rows: PopoverRow[] = [
        {
            show: true,
            title: t('common.export'),
            onClick: onExport,
            dataTestId: 'expert-responses-menu-item-export',
            withSeparator: false
        }
    ];
    const hasRowsToShow = rows.some((row) => row.show);
    return { rows, hasRowsToShow };
};
export const useEditedResponseVersions = () => {
    const { editedResponseVersions, setEditedResponseVersions } =
        useDataManagementContext();

    const updateEditedResponseVersions = (
        index: number,
        newResponse: string,
        userName: string,
        activate: boolean
    ) => {
        const today = new Date().toISOString();
        const newEditedResponseVersions = editedResponseVersions.reduce(
            (acc, message, messageIndex) => {
                const isTargetMessage = index === messageIndex;
                const isActiveMessage = activate && message.active;

                if (isTargetMessage) {
                    const newLine = {
                        ...message,
                        active: activate || message.active,
                        expert_response: newResponse,
                        user_name: userName,
                        date: today
                    };
                    activate ? acc.unshift(newLine) : acc.push(newLine);
                } else if (isActiveMessage) {
                    acc.push({
                        ...message,
                        active: false,
                        date: today
                    });
                } else {
                    acc.push(message);
                }
                return acc;
            },
            []
        );

        setEditedResponseVersions(newEditedResponseVersions);
    };

    const activateEditedResponseVersionsRows = (
        index: number,
        active: boolean
    ) => {
        const today = new Date().toISOString();
        const newEditedResponseVersions = editedResponseVersions.reduce(
            (acc, message, messageIndex) => {
                const isTargetMessage = index === messageIndex;
                if (isTargetMessage) {
                    acc.unshift({
                        ...message,
                        active,
                        date: today
                    });
                } else if (message.active) {
                    acc.push({
                        ...message,
                        active: false,
                        date: today
                    });
                } else {
                    acc.push(message);
                }
                return acc;
            },
            []
        );
        setEditedResponseVersions(newEditedResponseVersions);
    };

    return {
        editedResponseVersions,
        setEditedResponseVersions,
        updateEditedResponseVersions,
        activateEditedResponseVersionsRows
    };
};

export const useFetchData = (
    paginationModel: PaginationModel,
    sortModel: GridSortModel,
    setRowsCount: (count: number) => void
) => {
    const { expertResponsesSearchTerm, expertResponsesFilters } =
        useExpertResponsesFilters();
    const { setExpertResponsesRows, setAreExpertResponsesFetching } =
        useExpertResponsesRows();
    const { allIntegratedModels } = useAllIntegratedModels();

    useEffect(() => {
        setAreExpertResponsesFetching(true);
        const orderBy =
            sortModel[0].field === 'date'
                ? 'updated_at'
                : sortModel[0].field === 'model'
                ? 'tenant_model'
                : sortModel[0].field;
        Backend.fetchExpertResponses(
            expertResponsesFilters.models || [],
            expertResponsesSearchTerm,
            sortModel[0].sort,
            orderBy,
            paginationModel.page * DEFAULT_ROWS_LIMIT_PER_PAGE,
            DEFAULT_ROWS_LIMIT_PER_PAGE
        )
            .then((response) => {
                getAdditionalChatHistoryData(response.expert_responses).then(
                    (investigationIdsByChatId) => {
                        const newResponse = addIntegratedModelsToResponse(
                            response.expert_responses,
                            allIntegratedModels
                        );
                        newResponse.forEach((row: ExpertResponsesRow) => {
                            row.investigationId =
                                investigationIdsByChatId['chatsData']?.[
                                    row.chat_id
                                ]?.investigationId || null;
                        });
                        setExpertResponsesRows(newResponse);
                        setRowsCount(response.count);
                        setAreExpertResponsesFetching(false);
                    }
                );
            })
            .catch((ex: any) => {
                setAreExpertResponsesFetching(false);
                console.error('Failed to fetch data.', ex);
                window.sentry.log(ex);
            });
    }, [
        paginationModel,
        sortModel,
        expertResponsesSearchTerm,
        expertResponsesFilters
    ]);
};

export const useOpenThread = () => {
    const { onSelectedThread, setIsChatHistoryLoaded, setFirstThreadQuestion } =
        useThreadsHandling();
    const { setProductDetails } = useProductDetails();

    const { sendEvent } = useAnalytics();
    const dispatch = useDispatch();

    const onOpenThread = (row: ExpertResponsesRow) => {
        setIsChatHistoryLoaded(false);
        dispatch(resetWorkOrderState());
        sendEvent(ANALYTICS_EVENTS.CLICKED_THREAD);
        setFirstThreadQuestion(row.initial_question);
        onSelectedThread(row.chat_id, row.model, '', row.investigationId || '');
        if (row.integratedModel) {
            setProductDetails(row.integratedModel);
        }
    };
    return { onOpenThread };
};
