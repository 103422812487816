import { FunctionComponent, useState } from 'react';
import { Document, DocumentProps, pdfjs } from 'react-pdf';
import PDFPage from './PDFPage';
import EclipseLoader from '../../SVG/EclipseLoader';
import {
    getDocErrorHandlers,
    getPageErrorHandlers,
    getPageProxyMap
} from './helpers';

import { ANALYTICS_EVENTS, ANALYTICS_EVENT_PARAMS_KEYS } from '../../consts';
import useAnalytics from '../../hooks/useAnalytics';

type PDFDocumentProps = {
    sourcePath: string;
    sourceName: string;
    errorHandler?: Function;
    contentToHighlight?: string;
    pageNumToHighlight?: number;
    showDoc?: boolean;
    numPages: number;
    scale?: number;
    goToPage?: number;
    isDocRendered?: boolean;
    onPageInViewPort?: Function;
    onScrollIntoView?: Function;
    onPageRenderSuccess?: Function;
    showPages?: boolean;
    parentElement?: Element;
    pageRotateStepsMap?: object;
    dataTestId?: string;
} & DocumentProps;

const options = {
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`
};

const PDFDocument: FunctionComponent<PDFDocumentProps> = ({
    sourcePath,
    sourceName,
    showDoc = true,
    errorHandler = () => {},
    numPages,
    goToPage,
    scale = 1,
    onPageInViewPort,
    onScrollIntoView,
    onPageRenderSuccess,
    showPages = true,
    contentToHighlight,
    onLoadSuccess,
    onLoadProgress,
    isDocRendered,
    parentElement,
    pageNumToHighlight,
    pageRotateStepsMap = {},
    dataTestId = 'pdf-document'
}) => {
    const [pageProxyMap, setPageProxyMap] = useState<object>({});
    const [markedText, setmarkedText] = useState<string>('');
    const { sendEvent } = useAnalytics();

    const onDocLoadSuccess = (doc: any) => {
        onLoadSuccess?.(doc);
        setPageProxyMap(getPageProxyMap(doc));
    };

    const handleTextSelection = () => {
        const selection = window.getSelection();
        const selectedText = selection?.toString();
        if (selectedText) {
            setmarkedText(markedText);
            sendEvent(ANALYTICS_EVENTS.RESPONSE_TEXT_MARKED, {
                'mark type': ANALYTICS_EVENT_PARAMS_KEYS.SOURCE_MARK_TYPE,
                'marked text': selectedText,
                'source': sourceName
            });
        }
    };

    return (
        <Document
            externalLinkTarget="_blank"
            className={`pdf-viewer__document ${
                !showDoc ? 'pdf-viewer__document--hide' : ''
            }`}
            data-testid={dataTestId}
            file={sourcePath}
            onLoadSuccess={onDocLoadSuccess}
            options={options}
            onLoadProgress={onLoadProgress}
            {...getDocErrorHandlers(errorHandler)}
            loading={
                <EclipseLoader
                    height="90vh"
                    className=" flex align-center justify-center"
                />
            }
            onMouseUp={handleTextSelection}
        >
            <div data-testid={dataTestId}>
                {Array.from(new Array(numPages), (_, index) => {
                    const pageNum = index + 1;
                    // Hide  the highlight feature for now
                    // const content =
                    //     !pageNumToHighlight || pageNum === pageNumToHighlight
                    //         ? contentToHighlight
                    //         : '';
                    const content = '';
                    return (
                        <PDFPage
                            pageNumber={pageNum}
                            scrollIntoView={
                                isDocRendered && pageNum === goToPage
                            }
                            scale={scale}
                            onInViewPortChange={onPageInViewPort}
                            parentElement={parentElement}
                            key={pageNum}
                            onScrollIntoView={onScrollIntoView}
                            {...getPageErrorHandlers(errorHandler, pageNum)}
                            onRender={onPageRenderSuccess}
                            show={showPages}
                            contentToHighlight={content}
                            rotateSteps={pageRotateStepsMap[pageNum]}
                            page={pageProxyMap[pageNum]}
                        />
                    );
                })}
            </div>
        </Document>
    );
};

export default PDFDocument;
