import { SOURCE_DOCUMENT_DOMAIN, STORAGE } from "../types";

export const identifyStorageService = (url: string) => {
    if (url.includes(SOURCE_DOCUMENT_DOMAIN.AWS)) {
        return STORAGE.AWS;
    }
    return STORAGE.AZURE;
};

export const getFormattedVideoUrl = (url: string) => {
    const storage = identifyStorageService(url);

    if (storage === STORAGE.AWS) {
        return url.replace(/%20/g, '+');
    }
    return url;
}