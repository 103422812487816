import SingleModelThread from './SingleModelThread';
import { useModel, useThreadsHandling } from '../../AppContext';
import { ChatHistoryData } from '../../types';
import { useMemo } from 'react';

const ModelThreads = () => {
    const { chatHistoryData } = useThreadsHandling();
    const { isRangeEnabled } = useModel();

    const filterChatHistoryData = useMemo(() => {
        if (isRangeEnabled) {
            return chatHistoryData.flatMap((modelData) => {
                const serialNumberMap = modelData.data.reduce(
                    (acc, single_chat_data) => {
                        const serialNumber =
                            single_chat_data.model_serial_number ?? '';
                        if (!acc[serialNumber]) {
                            acc[serialNumber] = [];
                        }
                        acc[serialNumber].push(single_chat_data);
                        return acc;
                    },
                    {}
                );

                return Object.entries(serialNumberMap).map(
                    ([serialNumber, data]) =>
                        ({
                            tenant_model: modelData.tenant_model,
                            has_more: modelData.has_more,
                            data: data
                        } as ChatHistoryData)
                );
            });
        }
        return chatHistoryData;
    }, [chatHistoryData, isRangeEnabled]);

    if (!chatHistoryData[0].tenant_model) {
        return null;
    }

    return (
        <div data-testid="threads-model-list">
            {filterChatHistoryData.map((modelData, index) => {
                return (
                    <SingleModelThread
                        key={
                            isRangeEnabled
                                ? modelData.data[0].model_serial_number
                                : modelData.tenant_model
                        }
                        modelData={modelData}
                        modelIndex={index}
                    />
                );
            })}
        </div>
    );
};

export default ModelThreads;
