import { useSelector, useDispatch } from 'react-redux';
import {
    SmallWindowState,
    Panel,
    ProductDetailsResponse,
    IntegratedModel,
    MessageInfo,
    RecommendedQuestionsSource,
    FetchedSuggestions,
    FeedbackValue,
    QuestionPreset,
    FeedbackCategories,
    ChatHistoryData,
    SortingType,
    FeedbackType,
    SingleChatHistoryData
} from './types';
import { SourceDocument, FeedbackCategory } from './types';
import {
    useState,
    createContext,
    useContext,
    ReactNode,
    useEffect,
    useMemo,
    Dispatch,
    SetStateAction,
    FC,
    useRef
} from 'react';
import { Model } from './types/Metadata';
// TODO: Remove this import when we replace for the API
import { getUrlParams } from './utils/componentUtils';
import Backend from './backend/Backend';
import SFBackend from './backend/SFBackend';
import {
    getIntegratedModels,
    getModelFromIntegratedModels,
    getModelFromIntegratedModelsByIds
} from './utils/productUtils';
import useAnalytics from './hooks/useAnalytics';
import { ANALYTICS_EVENTS, MAX_MODELS_TO_FETCH } from './consts';
import {
    resetCoPilot,
    resetSuggestionsFound,
    saveInvestigationId,
    setInvestigationDetailsAction,
    setIsSmallChat,
    setResetCopilot,
    setScopFeedbacksMap,
    setScopMessages,
    setTriageChatId
} from './actions/coPilotActions';
import ScopSettings from './classes/ScopSettings';
import StarkBackend from './backend/StarkBackend';
import { isEqualStrings } from './utils/utils';
import {
    assignDataToChats,
    getAdditionalChatHistoryData,
    performCallWithTimeoutRetry
} from './utils/backendUtils';
import {
    resetObservationInputs,
    resetState,
    resetWorkOrderState,
    setScopSerialNumber
} from './actions/triageActions';
import {
    useChatWindowState,
    useHistoryMessages,
    useResetChatContext,
    useSmallChatInPortal
} from './context/ChatContext';
import useSwitchPanel from './hooks/useSwitchPanel';
import useSessionStorage from './hooks/useSessionStorage';
import { getPackageVersionNumber } from './selectors/packageVersionSelectors';
import Util from './Util';
import isEqual from 'lodash/isEqual';
import UserData from './classes/UserData';
import { useAskStreamContext } from './context/AskStreamContext';
import { t } from 'i18next';

export interface AppContextValue {
    seenSourceDocuments: Set<SourceDocument>;
    setSeenSourceDocuments: Dispatch<SetStateAction<Set<SourceDocument>>>;
    messages: Array<MessageInfo>;
    setMessages: Dispatch<SetStateAction<Array<MessageInfo>>>;
    windowState: SmallWindowState;
    setWindowState: Dispatch<SetStateAction<SmallWindowState>>;
    currentPanel: Panel;
    setCurrentPanel: Dispatch<SetStateAction<Panel>>;
    prevPanel: Panel;
    setPrevPanel: Dispatch<SetStateAction<Panel>>;
    model: string;
    setModel: Dispatch<SetStateAction<string>>;
    isRangeEnabled: boolean;
    setIsRangeEnabled: Dispatch<SetStateAction<boolean>>;
    modelSerialNumber: string;
    setModelSerialNumber: Dispatch<SetStateAction<string>>;
    isModelIntegrationError: boolean;
    setIsModelIntegrationError: Dispatch<SetStateAction<boolean>>;
    productDetails: IntegratedModel;
    setProductDetails: Dispatch<SetStateAction<IntegratedModel>>;
    initialSearch: string | null;
    setInitialSearch: Dispatch<SetStateAction<string>>;
    showSourceDocumentDetailsDialog: boolean;
    setShowSourceDocumentDetailsDialog: Dispatch<SetStateAction<boolean>>;
    selectedSourceDocumentIndex: number | null;
    setSelectedSourceDocumentIndex: Dispatch<SetStateAction<number | null>>;
    messageSourceDocuments: SourceDocument[];
    setMessageSourceDocuments: Dispatch<SetStateAction<SourceDocument[]>>;
    firstRequestText: string;
    setFirstRequestText: Dispatch<SetStateAction<string>>;
    recommendedQuestions: Array<string>;
    setRecommendedQuestions: Dispatch<SetStateAction<Array<string>>>;
    resetState: () => void;
    resetAndInitialApp: () => void;
    integratedModels: Array<IntegratedModel>;
    recommendedQuestionsSource: RecommendedQuestionsSource;
    setRecommendedQuestionsSource: Dispatch<
        SetStateAction<RecommendedQuestionsSource>
    >;
    fetchedSuggestions: FetchedSuggestions;
    setFetchedSuggestions: Dispatch<SetStateAction<FetchedSuggestions>>;
    triageLinkMessageIndexes: Array<number>;
    setTriageLinkMessageIndexes: Dispatch<SetStateAction<Array<number>>>;
    clickedMessagesNavigation: boolean;
    setClickedMessagesNavigation: Dispatch<SetStateAction<boolean>>;
    feedbacksMap: object;
    setFeedbacksMap: Dispatch<SetStateAction<object>>;
    scopSettings: ScopSettings;
    feedbackCategories: FeedbackCategories | null;
    setFeedbackCategories: Dispatch<SetStateAction<FeedbackCategories> | null>;
    questionPresets: string[];
    setQuestionPresets: Dispatch<SetStateAction<string[]>>;
    fetchedProductDetailsModelNames: Set<string>;
    setFetchedProductDetailsModelNames: Dispatch<SetStateAction<Set<string>>>;
    ingestedModels: Model[];
    fetchAndSaveIntegratedModels: (
        modelNames: string[]
    ) => Promise<IntegratedModel[]>;
    isFetchingIntegratedModels: boolean;
    isPortalInitialized: boolean;
    setIsPortalInitialized: Dispatch<SetStateAction<boolean>>;
    productTypeCatalog: object[];
    chatHistoryData: ChatHistoryData[];
    setChatHistoryData: Dispatch<SetStateAction<ChatHistoryData[]>>;
    threadsSortingType: SortingType;
    setThreadsSortingType: Dispatch<SetStateAction<SortingType>>;
    chatHistoryDataSearchText: string;
    setChatHistoryDataSearchText: Dispatch<SetStateAction<string>>;
    isThreadsPanelOpen: boolean;
    setIsThreadsPanelOpen: Dispatch<SetStateAction<boolean>>;
    selectedThread: string;
    setSelectedThread: Dispatch<SetStateAction<string>>;
    firstThreadQuestion: string;
    setFirstThreadQuestion: Dispatch<SetStateAction<string>>;
    messagesPerSelectedChat: { [key: string]: number };
    setMessagesPerSelectedChat: Dispatch<
        SetStateAction<{ [key: string]: number }>
    >;
    triageBtnIndexOnThreadClick: number;
    setTriageBtnIndexOnThreadClick: Dispatch<SetStateAction<number>>;
    suggestionQuestions: string[];
    setSuggestionQuestions: Dispatch<SetStateAction<string[]>>;
    isChatHistoryLoaded: boolean;
    setIsChatHistoryLoaded: Dispatch<SetStateAction<boolean>>;
    indexAndHeightOfEditedEntireMessage: { [key: string]: string };
    setIndexAndHeighOfEditedEntireMessage: Dispatch<
        SetStateAction<{ [key: string]: string }>
    >;
    selectedMessage: MessageInfo;
    setSelectedMessage: Dispatch<SetStateAction<MessageInfo>>;
    downloadInProgressFileList: Set<string>;
    setDownloadInProgressFileList: Dispatch<SetStateAction<Set<string>>>;
    fetchInitModels: () => Promise<
        [IntegratedModel[], Model[], boolean] | void
    >;
    userData: UserData;
    setUserData: Dispatch<SetStateAction<UserData>>;
    isServerlessIndex: boolean;
    setIsServerlessIndex: Dispatch<SetStateAction<boolean>>;
}

const AppContext = createContext<AppContextValue | null>(null);

export const AppContextProvider: FC<{ children: ReactNode }> = ({
    children
}) => {
    const [isChatHistoryLoaded, setIsChatHistoryLoaded] =
        useState<boolean>(true);
    const [messagesPerSelectedChat, setMessagesPerSelectedChat] = useState<{
        [key: string]: number;
    }>({});
    const [triageBtnIndexOnThreadClick, setTriageBtnIndexOnThreadClick] =
        useState<number>(null);
    const [firstThreadQuestion, setFirstThreadQuestion] = useState<string>('');
    const [selectedThread, setSelectedThread] = useState<string>('');
    const [threadsSortingType, setThreadsSortingType] = useState<SortingType>(
        SortingType.Date
    );
    const [chatHistoryData, setChatHistoryData] = useState<ChatHistoryData[]>(
        []
    );
    const [chatHistoryDataSearchText, setChatHistoryDataSearchText] =
        useState<string>('');
    const [messages, setMessages] = useState<Array<MessageInfo>>([]);
    const [windowState, setWindowState] = useState<SmallWindowState>(
        SmallWindowState.Initial
    );
    const [currentPanel, setCurrentPanel] = useState<Panel>(
        Panel.FirstRequestPanel
    );
    const [prevPanel, setPrevPanel] = useState<Panel>(null);
    const [model, setModel] = useState<string>(t('common.all'));
    const [isRangeEnabled, setIsRangeEnabled] = useState<boolean>();
    const [modelSerialNumber, setModelSerialNumber] = useState<string>(null);
    const [isModelIntegrationError, setIsModelIntegrationError] =
        useState<boolean>();
    const [productDetails, setProductDetails] = useState<IntegratedModel>(null);
    const [recommendedQuestions, setRecommendedQuestions] = useState<
        Array<string>
    >([]);
    const [recommendedQuestionsSource, setRecommendedQuestionsSource] =
        useState<RecommendedQuestionsSource>(null);
    const [initialSearch, setInitialSearch] = useState<string | null>(null);
    const [
        showSourceDocumentDetailsDialog,
        setShowSourceDocumentDetailsDialog
    ] = useState<boolean>(false);
    const [selectedSourceDocumentIndex, setSelectedSourceDocumentIndex] =
        useState<number | null>(null);
    const [messageSourceDocuments, setMessageSourceDocuments] = useState<
        SourceDocument[]
    >([]);
    const [firstRequestText, setFirstRequestText] = useState<string>('');
    const [integratedModels, setIntegratedModels] = useState<
        Array<IntegratedModel>
    >([]);
    const [ingestedModels, setIngestedModels] = useState<Array<Model>>([]);
    const [fetchedSuggestions, setFetchedSuggestions] =
        useState<FetchedSuggestions>({ solutions: [], observations: [] });

    const { isSmallChat, resetCopilot, investigatedModel } = useSelector(
        (state: any) => state.coPilotReducer
    );

    const triageBaseUrl = useSelector(
        (state: any) => state.appSettingsReducer?.triageBaseUrl
    );
    const { accessToken, username } = useSelector(
        (state: any) => state.globalReducer
    );

    const locale = useSelector((state: any) => state.globalReducer?.locale);

    const [seenSourceDocuments, setSeenSourceDocuments] = useState<
        Set<SourceDocument>
    >(new Set());
    const [triageLinkMessageIndexes, setTriageLinkMessageIndexes] = useState<
        Array<number>
    >([1]);
    const [clickedMessagesNavigation, setClickedMessagesNavigation] =
        useState<boolean>(false);

    const [feedbacksMap, setFeedbacksMap] = useState<object>({});
    const [scopSettings, setScopSettings] = useState<ScopSettings>(
        new ScopSettings()
    );
    const [userData, setUserData] = useState<UserData>(new UserData());
    const [feedbackCategories, setFeedbackCategories] =
        useState<FeedbackCategories>();
    const [questionPresets, setQuestionPresets] = useState<string[]>([]);
    const [suggestionQuestions, setSuggestionQuestions] = useState<string[]>(
        []
    );
    const [
        fetchedProductDetailsModelNames,
        setFetchedProductDetailsModelNames
    ] = useState<Set<string>>(new Set());
    const [isFetchingIntegratedModels, setIsFetchingIntegratedModels] =
        useState<boolean>(false);
    const [isPortalInitialized, setIsPortalInitialized] = useState(false);
    const [productTypeCatalog, setProductTypeCatalog] = useState<
        Array<Record<string, any>>
    >([]);
    const [isThreadsPanelOpen, setIsThreadsPanelOpen] = useState<boolean>(true);
    const [
        indexAndHeightOfEditedEntireMessage,
        setIndexAndHeighOfEditedEntireMessage
    ] = useState<{ [key: string]: string }>({});

    const packageVersion = useSelector(getPackageVersionNumber);
    const { clickedClearAll } = useSelector(
        (state: any) => state.deviceInspectionReducer
    );
    const [selectedMessage, setSelectedMessage] = useState<MessageInfo>(null);
    const [downloadInProgressFileList, setDownloadInProgressFileList] =
        useState<Set<string>>(new Set());

    // isServerlessIndex was in use to disable persona actions in data management panel to prevent vectors update
    // If needed can be got from metadata response as "serverless_index"
    const [isServerlessIndex, setIsServerlessIndex] = useState<boolean>(false);

    const dispatch = useDispatch();

    const resetState = () => {
        setMessages([]);
        setSeenSourceDocuments(new Set());
        setWindowState(SmallWindowState.Initial);
        setCurrentPanel(Panel.FirstRequestPanel);
        setPrevPanel(null);
        setModel(t('common.all'));
        setProductDetails(null);
        setInitialSearch(null);
        setShowSourceDocumentDetailsDialog(false);
        setSelectedSourceDocumentIndex(null);
        setFirstRequestText('');
        setTriageChatId(null);
        setRecommendedQuestionsSource(null);
        setRecommendedQuestions([]);
        setFetchedSuggestions({ solutions: [], observations: [] });
        setFeedbacksMap({});
        setFeedbackCategories(null);
        setIsFetchingIntegratedModels(false);
        setIsThreadsPanelOpen(true);
        setIndexAndHeighOfEditedEntireMessage({});
        setSelectedMessage(null);
    };

    const resetAndInitialApp = () => {
        resetState();
        initializeApp();
    };

    useEffect(() => {
        if (clickedClearAll) {
            setRecommendedQuestionsSource(null);
            setRecommendedQuestions([]);
            dispatch(resetSuggestionsFound());
            setFetchedSuggestions({ solutions: [], observations: [] });
        }
    }, [clickedClearAll]);

    useEffect(() => {
        if (resetCopilot) {
            resetAndInitialApp();
            dispatch(setResetCopilot(false));
        }
    }, [resetCopilot]);

    useEffect(() => {
        //Handle Triage model change
        if (!isSmallChat) {
            return;
        }
        const isModelChanged = !isEqualStrings(
            model,
            investigatedModel.modelName
        );
        if (isModelChanged && ingestedModels.length) {
            const ingestedModel = ingestedModels.find((model) =>
                isEqualStrings(model.name, investigatedModel.modelName)
            );
            if (ingestedModel) {
                setModel(ingestedModel.name);
                setProductDetailsFromInvestigatedModel();
            } else {
                setModel(t('common.all'));
            }
        }
    }, [investigatedModel, ingestedModels]);

    useEffect(() => {
        if (!messages.length) {
            setSelectedMessage(null);
        }
    }, [messages.length]);

    const previousMessagesRef = useRef(messages);

    useEffect(() => {
        if (!isEqual(previousMessagesRef.current, messages)) {
            previousMessagesRef.current = messages;
            dispatch(setScopMessages(messages));
        }
    }, [messages, previousMessagesRef]);

    const previousFeedbacksMapRef = useRef(feedbacksMap);

    useEffect(() => {
        if (!isEqual(previousFeedbacksMapRef.current, feedbacksMap)) {
            previousFeedbacksMapRef.current = feedbacksMap;
            dispatch(setScopFeedbacksMap(feedbacksMap));
        }
    }, [feedbacksMap, previousFeedbacksMapRef]);

    const setProductDetailsFromInvestigatedModel = async () => {
        const integratedModels = await fetchAndSaveIntegratedModels([
            investigatedModel.modelName
        ]);
        const chosenModel =
            getModelFromIntegratedModelsByIds(
                integratedModels,
                investigatedModel.modelName,
                investigatedModel.productTypeId,
                investigatedModel.manufacturerId
            ) || null;
        if (chosenModel) {
            setProductDetails(chosenModel);
        }
    };

    const fetchInitModels = async (): Promise<
        [IntegratedModel[], Model[], boolean] | void
    > => {
        const integratedModelsCopy: IntegratedModel[] = [...integratedModels];
        setIntegratedModels([]);
        try {
            const [metadataRes, productTypeCatalogRes] = await Promise.all([
                performCallWithTimeoutRetry(async () =>
                    Backend.fetchMetadata(true)
                ),
                SFBackend.fetchCatalogProductType()
            ]);
            setProductTypeCatalog(productTypeCatalogRes.catalog_data);
            // Disable persona actions in data management panel to prevent vectors update for serverless users
            // setIsServerlessIndex(metadataRes.data.serverless_index);
            const isRangeEnabled = metadataRes.data.model_range_enabled;
            setIsRangeEnabled(isRangeEnabled);
            const ingestedModels: Model[] = metadataRes.data.models;
            setIngestedModels(ingestedModels);
            if (!isSmallChat) {
                const newIntegratedModels = await fetchAndSaveIntegratedModels(
                    ingestedModels.map((model) => model.name),
                    productTypeCatalogRes.catalog_data,
                    true
                );
                return [newIntegratedModels, ingestedModels, isRangeEnabled];
            }
        } catch (ex: any) {
            console.error('Failed to fetch initial models.', ex);
            window.sentry.log(ex);
            setIntegratedModels(integratedModelsCopy);
        }
    };
    const initializeApp = async () => {
        const urlParams = getUrlParams();

        if (!isSmallChat) {
            fetchChatHistory('', '', SortingType.Date, 0, setChatHistoryData);
        }
        StarkBackend.getScopSettings(triageBaseUrl, accessToken)
            .then((response) => {
                setScopSettings(new ScopSettings(response, packageVersion));
            })
            .catch(() => console.error('Failed to fetch scop settings.'));

        StarkBackend.getUserData(triageBaseUrl, accessToken, username)
            .then((userData) => setUserData(new UserData(userData.data)))
            .catch(() => console.error('Failed to fetch user data.'));
        Backend.getFeedbackCategories(locale).then((response) => {
            const translatedCategories = response.data.map(
                (category: FeedbackCategory) => category.translated_value
            );
            const [question, ...chips] = translatedCategories;
            setFeedbackCategories({ question, chips });
        });
        Backend.getQuestionPresets(locale).then((response) => {
            const translatedQuestions = response.data.map(
                (question: QuestionPreset) => question.translated_value
            );
            setQuestionPresets(translatedQuestions);
        });
        const [newIntegratedModels, ingestedModels, isRangeEnabled] =
            (await fetchInitModels()) || [[], [], false];

        if (!isSmallChat) {
            setIntegratedModels(newIntegratedModels);
            let model;
            if (isRangeEnabled) {
                if (urlParams.serialNumber) {
                    const serialNumber = urlParams.serialNumber.toUpperCase();
                    const data = await Backend.fetchRangedModel(serialNumber);
                    if (data.is_valid) {
                        model = data.response;
                        setModelSerialNumber(serialNumber);
                        dispatch(setScopSerialNumber(serialNumber));
                    } else {
                        setIsModelIntegrationError(true);
                    }
                }
            } else if (urlParams.model) {
                model = urlParams.model;
            }

            if (model) {
                const chosenModel =
                    getModelFromIntegratedModels(
                        newIntegratedModels,
                        model,
                        urlParams.productType,
                        urlParams.manufacturer
                    ) || null;
                const ingestedModel = ingestedModels.find((model) =>
                    isEqualStrings(model.name, chosenModel?.modelName)
                );
                setModel(ingestedModel?.name || t('common.all'));
                setProductDetails(ingestedModel ? chosenModel : null);
            }
        }
    };

    useEffect(() => {
        initializeApp();
    }, []);

    const fetchAndSaveIntegratedModels = async (
        modelNames: string[],
        ptCatalog: object[] = null,
        shouldRefreshModelsData: boolean = false
    ) => {
        ptCatalog = ptCatalog || productTypeCatalog;
        let modelsToFetch = modelNames.slice(0, MAX_MODELS_TO_FETCH);
        if (!shouldRefreshModelsData) {
            modelsToFetch = modelNames.filter(
                (modelName) =>
                    !fetchedProductDetailsModelNames.has(
                        modelName.toLocaleLowerCase()
                    )
            );
        }

        if (modelsToFetch.length) {
            const newSet = new Set(
                !shouldRefreshModelsData ? fetchedProductDetailsModelNames : []
            );
            modelsToFetch.forEach((modelName) =>
                newSet.add(modelName.toLocaleLowerCase())
            );
            setFetchedProductDetailsModelNames(newSet);
            let filteredProductDetails = [];
            if (ptCatalog?.length) {
                setIsFetchingIntegratedModels(true);
                try {
                    const productDetails: ProductDetailsResponse =
                        await SFBackend.fetchProductDetails(modelsToFetch);
                    //Filter PTs to cover Product Type Groups usage
                    const catalogPTIds = new Set(
                        ptCatalog.map((pt: any) => pt.id)
                    );
                    filteredProductDetails = productDetails.product.filter(
                        (item) => catalogPTIds.has(item.producttype.id)
                    );
                } catch (ex: any) {
                    console.error(ex);
                    window.sentry.log(ex);
                    setIsFetchingIntegratedModels(false);
                }
            }

            const newIntegratedModels = getIntegratedModels(
                filteredProductDetails,
                modelsToFetch
            );
            setIntegratedModels([
                ...(!shouldRefreshModelsData ? integratedModels : []),
                ...newIntegratedModels
            ]);
            setIsFetchingIntegratedModels(false);
            return newIntegratedModels;
        }

        return integratedModels;
    };

    const value: AppContextValue = {
        seenSourceDocuments,
        setSeenSourceDocuments,
        currentPanel,
        setCurrentPanel,
        prevPanel,
        setPrevPanel,
        model,
        setModel,
        isRangeEnabled,
        setIsRangeEnabled,
        modelSerialNumber,
        setModelSerialNumber,
        isModelIntegrationError,
        setIsModelIntegrationError,
        initialSearch,
        setInitialSearch,
        windowState,
        setWindowState,
        showSourceDocumentDetailsDialog,
        setShowSourceDocumentDetailsDialog,
        selectedSourceDocumentIndex,
        setSelectedSourceDocumentIndex,
        messageSourceDocuments,
        setMessageSourceDocuments,
        firstRequestText,
        setFirstRequestText,
        recommendedQuestions,
        setRecommendedQuestions,
        resetState,
        resetAndInitialApp,
        integratedModels,
        productDetails,
        setProductDetails,
        messages,
        setMessages,
        recommendedQuestionsSource,
        setRecommendedQuestionsSource,
        fetchedSuggestions,
        setFetchedSuggestions,
        triageLinkMessageIndexes,
        setTriageLinkMessageIndexes,
        clickedMessagesNavigation,
        setClickedMessagesNavigation,
        feedbacksMap,
        setFeedbacksMap,
        scopSettings,
        fetchedProductDetailsModelNames,
        setFetchedProductDetailsModelNames,
        ingestedModels,
        fetchAndSaveIntegratedModels,
        isFetchingIntegratedModels,
        feedbackCategories,
        setFeedbackCategories,
        questionPresets,
        setQuestionPresets,
        isPortalInitialized,
        setIsPortalInitialized,
        productTypeCatalog,
        chatHistoryData,
        setChatHistoryData,
        threadsSortingType,
        setThreadsSortingType,
        chatHistoryDataSearchText,
        setChatHistoryDataSearchText,
        isThreadsPanelOpen,
        setIsThreadsPanelOpen,
        selectedThread,
        setSelectedThread,
        firstThreadQuestion,
        setFirstThreadQuestion,
        messagesPerSelectedChat,
        setMessagesPerSelectedChat,
        triageBtnIndexOnThreadClick,
        setTriageBtnIndexOnThreadClick,
        suggestionQuestions,
        setSuggestionQuestions,
        isChatHistoryLoaded,
        setIsChatHistoryLoaded,
        indexAndHeightOfEditedEntireMessage,
        setIndexAndHeighOfEditedEntireMessage,
        selectedMessage,
        setSelectedMessage,
        downloadInProgressFileList,
        setDownloadInProgressFileList,
        fetchInitModels,
        userData,
        setUserData,
        isServerlessIndex,
        setIsServerlessIndex
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = (): AppContextValue => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('Did you forget to use the Provider?');
    }

    return context;
};

export const useCurrentPanel = () => {
    const { currentPanel, setCurrentPanel, prevPanel, setPrevPanel } =
        useAppContext();
    const isCoPilotPanel = currentPanel === Panel.CoPilotPanel;
    return {
        currentPanel,
        setCurrentPanel,
        isCoPilotPanel,
        prevPanel,
        setPrevPanel
    };
};

export const useModel = () => {
    const {
        model,
        setModel,
        ingestedModels,
        isRangeEnabled,
        modelSerialNumber,
        setModelSerialNumber,
        isModelIntegrationError,
        setIsModelIntegrationError
    } = useAppContext();

    const dispatch = useDispatch();

    const setModelData = async (newModel: string, newSerialNumber?: string) => {
        const ingestedModel = ingestedModels.find((model) =>
            isEqualStrings(model.name, newModel)
        );
        setModel(ingestedModel?.name || t('common.all'));
        if (newSerialNumber) {
            handleNewSerialNumber(newSerialNumber);
        }
    };

    const handleNewSerialNumber = (newSerialNumber: string) => {
        setModelSerialNumber(newSerialNumber);
        dispatch(setScopSerialNumber(newSerialNumber));
    };
    return {
        model,
        setModelData,
        setModel,
        isRangeEnabled,
        modelSerialNumber,
        setModelSerialNumber,
        isModelIntegrationError,
        setIsModelIntegrationError,
        handleNewSerialNumber
    };
};

export const useProductDetails = () => {
    const { productDetails, setProductDetails } = useAppContext();
    return { productDetails, setProductDetails };
};

export const useInitialSearch = () => {
    const { initialSearch, setInitialSearch } = useAppContext();
    return { initialSearch, setInitialSearch };
};

export const useSuggestionQuestions = () => {
    const { suggestionQuestions, setSuggestionQuestions } = useAppContext();

    return { suggestionQuestions, setSuggestionQuestions };
};

export const useWindowState = () => {
    const { windowState, setWindowState } = useAppContext();
    return { windowState, setWindowState };
};

export const useKAModal = () => {
    const { sendEvent, startDurationTimerEvent } = useAnalytics();
    const {
        showSourceDocumentDetailsDialog,
        setShowSourceDocumentDetailsDialog,
        selectedSourceDocumentIndex,
        setSelectedSourceDocumentIndex,
        messageSourceDocuments,
        setMessageSourceDocuments,
        seenSourceDocuments,
        setSeenSourceDocuments,
        messages
    } = useAppContext();
    const { setIsSmallChatInPortal } = useSmallChatInPortal();
    const { historyMessages } = useHistoryMessages();

    useEffect(() => {
        const selectedSource =
            messageSourceDocuments[selectedSourceDocumentIndex];
        addSourceDocumentToSeenList(selectedSource);
    }, [selectedSourceDocumentIndex, messageSourceDocuments]);

    const hideSourceDocumentDetailsDialog = () => {
        if (!messageSourceDocuments[selectedSourceDocumentIndex]) {
            return;
        }
        setSelectedSourceDocumentIndex(null);
        setShowSourceDocumentDetailsDialog(false);
        setIsSmallChatInPortal(false);
        sendEvent(ANALYTICS_EVENTS.SOURCE_WINDOW_TIME_SPENT_ON_SOURCE, {
            'Source name':
                messageSourceDocuments[selectedSourceDocumentIndex].metadata
                    .filename,
            'Page number':
                messageSourceDocuments[selectedSourceDocumentIndex].metadata
                    .page
        });
    };

    const handleShowSourceDocumentDetails = (
        index: number,
        sd: SourceDocument[],
        type: string
    ) => {
        setIsSmallChatInPortal(true);
        setSelectedSourceDocumentIndex(index);
        setMessageSourceDocuments(sd);
        setShowSourceDocumentDetailsDialog(true);
        startDurationTimerEvent(
            ANALYTICS_EVENTS.SOURCE_WINDOW_TIME_SPENT_ON_SOURCE
        );
        sendEvent(ANALYTICS_EVENTS.KNOWLEDGE_SOURCE_CLICK, {
            'Knowledge source name': sd[index].metadata.filename,
            'Type of source': type
        });
    };

    const parentMessage = useMemo(() => {
        if (messageSourceDocuments.length) {
            let message = messages.find(
                (message) =>
                    message.rowID === messageSourceDocuments[0].messageId
            );
            if (!message && Object.keys(historyMessages).length) {
                message = Object.values(historyMessages)
                    .flat()
                    .find(
                        (message) =>
                            message.rowID ===
                            messageSourceDocuments[0].messageId
                    );
            }

            return message;
        } else {
            return null;
        }
    }, [messageSourceDocuments]);

    const addSourceDocumentToSeenList = (doc: SourceDocument) => {
        if (!seenSourceDocuments.has(doc)) {
            const newSeenResults = new Set(seenSourceDocuments);
            newSeenResults.add(doc);
            setSeenSourceDocuments(newSeenResults);
        }
    };

    const getIsSourceActive = (source: SourceDocument) =>
        source &&
        source === messageSourceDocuments[selectedSourceDocumentIndex];

    return {
        showSourceDocumentDetailsDialog,
        hideSourceDocumentDetailsDialog,
        handleShowSourceDocumentDetails,
        selectedSourceDocumentIndex,
        messageSourceDocuments,
        setSelectedSourceDocumentIndex,
        seenSourceDocuments,
        parentMessage,
        getIsSourceActive
    };
};
export const useFirstRequestText = () => {
    const { firstRequestText, setFirstRequestText } = useAppContext();
    const resetFirstRequestText = () => setFirstRequestText('');
    return { firstRequestText, setFirstRequestText, resetFirstRequestText };
};

export const useRecommendedQuestions = () => {
    const {
        recommendedQuestions,
        setRecommendedQuestions,
        recommendedQuestionsSource,
        setRecommendedQuestionsSource
    } = useAppContext();
    return {
        recommendedQuestions,
        setRecommendedQuestions,
        recommendedQuestionsSource,
        setRecommendedQuestionsSource
    };
};

export const useIntegratedModels = () => {
    const {
        integratedModels,
        ingestedModels,
        fetchAndSaveIntegratedModels,
        isFetchingIntegratedModels,
        fetchInitModels
    } = useAppContext();

    return {
        integratedModels,
        fetchAndSaveIntegratedModels,
        ingestedModels,
        isFetchingIntegratedModels,
        fetchInitModels
    };
};

export const useMessages = () => {
    const { messages, setMessages } = useAppContext();
    return { messages, setMessages };
};

export const useFetchedSuggestions = () => {
    const { fetchedSuggestions, setFetchedSuggestions } = useAppContext();
    return { fetchedSuggestions, setFetchedSuggestions };
};

export const useTriageLinkMessageIndexes = () => {
    const { triageLinkMessageIndexes, setTriageLinkMessageIndexes } =
        useAppContext();

    const addTriageLinkMessageIndex = (index: number) =>
        setTriageLinkMessageIndexes([...triageLinkMessageIndexes, index]);

    const resetTriageLinkMessageIndexes = () => {
        setTriageLinkMessageIndexes([1]);
    };
    return {
        triageLinkMessageIndexes,
        addTriageLinkMessageIndex,
        resetTriageLinkMessageIndexes
    };
};

export const usePortalInitialization = () => {
    const { isPortalInitialized, setIsPortalInitialized } = useAppContext();
    return { isPortalInitialized, setIsPortalInitialized };
};

export const useClickedClearAndBack = () => {
    const {
        setInitialSearch,
        setMessages,
        setFetchedSuggestions,
        setTriageLinkMessageIndexes,
        setProductDetails,
        setIndexAndHeighOfEditedEntireMessage
    } = useAppContext();
    const { cancelStream } = useAskStreamContext();
    const onClearAndBack = () => {
        setInitialSearch('');
        setMessages([]);
        setFetchedSuggestions({ solutions: [], observations: [] });
        setTriageLinkMessageIndexes([1]);
        setProductDetails(null);
        cancelStream();
        setIndexAndHeighOfEditedEntireMessage({});
    };
    return { onClearAndBack };
};

export const useClickedMessagesNavigation = () => {
    const { clickedMessagesNavigation, setClickedMessagesNavigation } =
        useAppContext();
    return { clickedMessagesNavigation, setClickedMessagesNavigation };
};

export const useFeedback = (key: string) => {
    const { feedbacksMap, setFeedbacksMap, model, messages } = useAppContext();
    const [dontAsk, setDontAsk] = useSessionStorage(
        'dontAskMessageFeedback',
        false
    );

    const setFeedbackData = (id: string, type: string) => {
        setFeedbacksMap({
            ...feedbacksMap,
            [key]: { type, id }
        });
    };

    const setFeedbackValue = (type: FeedbackValue) => {
        setFeedbacksMap({
            ...feedbacksMap,
            [key]: { ...feedbacksMap[key], type }
        });
    };

    const sendFeedback = async (
        feedbackValue: FeedbackValue,
        categories: Array<string> = null,
        feedbackText: string = null,
        message: MessageInfo,
        feedbackType: FeedbackType,
        historyMessage?: MessageInfo,
        sourceDocument?: SourceDocument
    ) => {
        if (!message || !model) {
            return;
        }
        const currentMessage = historyMessage || message;
        const currentSource = sourceDocument
            ? {
                  page_content: sourceDocument.content,
                  metadata: sourceDocument.metadata
              }
            : null;
        const isUseful =
            feedbackValue === FeedbackValue.Like
                ? true
                : feedbackValue === FeedbackValue.Dislike
                ? false
                : null;

        const isFirstQuestion = messages.indexOf(message) < 3;

        try {
            const response = await Backend.sendFeedback(
                model,
                feedbacksMap[key]?.id,
                currentMessage.questionId ||
                    messages[messages.indexOf(message) - 1]?.message,
                currentMessage.message,
                isUseful,
                categories,
                feedbackText,
                currentSource,
                currentMessage.rowID,
                isFirstQuestion,
                feedbackType,
                feedbackValue
            );

            setFeedbackData(response?.data?.rowid, feedbackValue);
        } catch (ex: any) {
            window.sentry.log(ex);
        }
    };

    return {
        feedbackData: feedbacksMap[key],
        setFeedbackValue,
        sendFeedback,
        dontAsk,
        setDontAsk
    };
};

export const useScopSettings = () => {
    const { scopSettings } = useAppContext();
    return scopSettings;
};

export const useIsSearchQuestion = () => {
    const { scopSettings } = useAppContext();
    return scopSettings;
};

export const useThreadsHandling = () => {
    const {
        chatHistoryData,
        setChatHistoryData,
        threadsSortingType,
        setThreadsSortingType,
        chatHistoryDataSearchText,
        setChatHistoryDataSearchText,
        selectedThread,
        setSelectedThread,
        firstThreadQuestion,
        setFirstThreadQuestion,
        messagesPerSelectedChat,
        setMessagesPerSelectedChat,
        triageBtnIndexOnThreadClick,
        setTriageBtnIndexOnThreadClick,
        isChatHistoryLoaded,
        setIsChatHistoryLoaded,
        currentPanel,
        model
    } = useAppContext();
    const { handleMaximizeChatWindow, handleInitializeChatWindow } =
        useChatWindowState();

    const dispatch = useDispatch();
    const { reset } = useResetOnNewChatLoad();

    const onChangeSortingType = async (sortingType: SortingType) => {
        setThreadsSortingType(sortingType);
        fetchChatHistory('', '', sortingType, 0, setChatHistoryData);
    };

    const onSearchThread = async (value: string) => {
        setChatHistoryDataSearchText(value);
        fetchChatHistory('', value, threadsSortingType, 0, setChatHistoryData);
    };

    const getAnotherChunkOfChatHistory = async (
        offset: number,
        setLoadingThreads: Dispatch<SetStateAction<boolean>>
    ) => {
        fetchChatHistory(
            '',
            chatHistoryDataSearchText,
            threadsSortingType,
            offset,
            setChatHistoryData,
            true,
            setLoadingThreads
        );
    };

    const getAnotherChunkOfChatHistoryByModel = async (
        tenantModel: string,
        offset: number,
        setLoadingThreads: Dispatch<SetStateAction<boolean>>
    ) => {
        fetchChatHistory(
            tenantModel,
            chatHistoryDataSearchText,
            SortingType.Model,
            offset,
            setChatHistoryData,
            true,
            setLoadingThreads
        );
    };

    const onSelectedThread = (
        chatId: string,
        model: string,
        modelSerialNumber: string = '',
        investigationIdFromThread: string = '',
        goToTriage: boolean = false
    ) => {
        if (selectedThread !== chatId) {
            setSelectedThread(chatId);
            reset(model, modelSerialNumber);
            dispatch(setTriageChatId(chatId, model));
            dispatch(resetState());
            dispatch(resetObservationInputs());
            dispatch(resetWorkOrderState());
            dispatch(setInvestigationDetailsAction(null));
            if (investigationIdFromThread) {
                dispatch(saveInvestigationId(investigationIdFromThread));
            }
        }
        setTimeout(() => {
            if (goToTriage) {
                dispatch(setIsSmallChat(true));

                const rootElement = document.getElementById('stark');
                rootElement.style.display = 'flex';
                if (currentPanel === Panel.CoPilotPanel) {
                    handleMaximizeChatWindow();
                } else {
                    handleInitializeChatWindow();
                }
            }
        }, 0);
    };

    const addNewThread = (newChat: SingleChatHistoryData) => {
        if (!chatHistoryData.length) {
            if (threadsSortingType === SortingType.Date) {
                setChatHistoryData([
                    {
                        data: [newChat],
                        tenant_model: null,
                        has_more: false
                    }
                ]);
            } else {
                setChatHistoryData([
                    {
                        data: [newChat],
                        tenant_model: model,
                        has_more: false
                    }
                ]);
            }
        } else if (chatHistoryData[0].tenant_model === null) {
            setChatHistoryData([
                {
                    ...chatHistoryData[0],
                    data: [newChat, ...chatHistoryData[0].data]
                }
            ]);
        } else {
            const modelIndex = chatHistoryData.findIndex(
                (obj) => obj.tenant_model === model
            );
            if (modelIndex === -1) {
                setChatHistoryData([
                    ...chatHistoryData,
                    {
                        data: [newChat],
                        tenant_model: model,
                        has_more: false
                    }
                ]);
            } else {
                const modelData = chatHistoryData[modelIndex];
                setChatHistoryData([
                    ...chatHistoryData.slice(0, modelIndex),
                    {
                        ...modelData,
                        data: [newChat, ...modelData.data]
                    },
                    ...chatHistoryData.slice(modelIndex + 1)
                ]);
            }
        }
    };

    return {
        chatHistoryData,
        setChatHistoryData,
        threadsSortingType,
        onChangeSortingType,
        onSearchThread,
        getAnotherChunkOfChatHistory,
        selectedThread,
        setSelectedThread,
        getAnotherChunkOfChatHistoryByModel,
        onSelectedThread,
        firstThreadQuestion,
        setFirstThreadQuestion,
        messagesPerSelectedChat,
        setMessagesPerSelectedChat,
        triageBtnIndexOnThreadClick,
        setTriageBtnIndexOnThreadClick,
        isChatHistoryLoaded,
        setIsChatHistoryLoaded,
        addNewThread
    };
};

export const useResetOnNewChatLoad = () => {
    const {
        setMessages,
        setSeenSourceDocuments,
        setProductDetails,
        setInitialSearch,
        setShowSourceDocumentDetailsDialog,
        setSelectedSourceDocumentIndex,
        setFirstRequestText,
        setRecommendedQuestionsSource,
        setRecommendedQuestions,
        setFetchedSuggestions,
        setFeedbacksMap
    } = useAppContext();

    const { resetAskStreamContext } = useAskStreamContext();
    const { resetChatContext } = useResetChatContext();
    const { setModelData } = useModel();

    const reset = (model: string, modelSerialNumber?: string) => {
        resetAskStreamContext();
        resetChatContext();
        setMessages([]);
        setSeenSourceDocuments(new Set());
        setModelData(model, modelSerialNumber);
        setProductDetails(null);
        setInitialSearch(null);
        setShowSourceDocumentDetailsDialog(false);
        setSelectedSourceDocumentIndex(null);
        setFirstRequestText('');
        setTriageChatId(null);
        setRecommendedQuestionsSource(null);
        setRecommendedQuestions([]);
        setFetchedSuggestions({ solutions: [], observations: [] });
        setFeedbacksMap({});
    };

    return { reset };
};

const fetchChatHistory = (
    tenantModel: string = '',
    searchTerm: string = '',
    order_by: SortingType = SortingType.Date,
    offset = 0,
    setChatHistoryData: Dispatch<SetStateAction<ChatHistoryData[]>>,
    addChunkToExisting: boolean = false,
    setLoadingThreads: Dispatch<SetStateAction<boolean>> | null = null
) => {
    let payload = {
        tenant_model: '',
        search_term: searchTerm,
        is_descending: true,
        offset,
        limit: 20
    };
    if (order_by === SortingType.Date) {
        Backend.getChatHistorySession(payload).then((result) => {
            if (result.status === 200) {
                getAdditionalChatHistoryData(result.data.data).then(
                    (response) => {
                        const { chatsData, usersData } = response;
                        const data = assignDataToChats(
                            chatsData,
                            usersData,
                            result.data
                        );
                        if (addChunkToExisting) {
                            setChatHistoryData((prev) => {
                                return [
                                    {
                                        data: [...prev[0].data, ...data.data],
                                        tenant_model: null,
                                        has_more: data.has_more
                                    }
                                ];
                            });
                        } else {
                            setChatHistoryData([
                                {
                                    data: data.data,
                                    tenant_model: null,
                                    has_more: data.has_more
                                }
                            ]);
                        }
                        if (setLoadingThreads) {
                            setLoadingThreads(false);
                        }
                    }
                );
            }
        });
    } else if (!tenantModel) {
        const modelsPayload = {
            search_term: searchTerm,
            offset: 0,
            limit: 10,
            limit_per_model: 3
        };
        Backend.getChatHistorySessionByModels(modelsPayload).then((result) => {
            if (result.status === 200) {
                getAdditionalChatHistoryData(result.data.data, true).then(
                    (response) => {
                        const { chatsData, usersData } = response;
                        for (const obj of result.data.data) {
                            obj.data.forEach((thread) => {
                                if (thread.tenant_model)
                                    thread['investigation_id'] =
                                        chatsData[thread.chat_id]
                                            ?.investigationId || null;
                                thread['user_email'] =
                                    usersData[thread.user_external_id]
                                        ?.userEmail || null;
                            });
                        }
                        setChatHistoryData(result.data.data);
                    }
                );
            }
        });
    } else if (tenantModel) {
        payload.tenant_model = tenantModel;
        payload.limit = 3;
        Backend.getChatHistorySession(payload).then((result) => {
            if (result.status === 200) {
                getAdditionalChatHistoryData(result.data.data).then(
                    (response) => {
                        const { chatsData, usersData } = response;
                        const data = assignDataToChats(
                            chatsData,
                            usersData,
                            result.data
                        );
                        setChatHistoryData((prev) => {
                            const tempArr = prev.map((obj) => {
                                if (obj.tenant_model === tenantModel) {
                                    return {
                                        tenant_model: tenantModel,
                                        has_more: data.has_more,
                                        data: [...obj.data, ...data.data]
                                    };
                                }
                                return obj;
                            });
                            return tempArr;
                        });

                        if (setLoadingThreads) {
                            setLoadingThreads(false);
                        }
                    }
                );
            }
        });
    }
};
export const useThreadsPanel = () => {
    const { isThreadsPanelOpen, setIsThreadsPanelOpen } = useAppContext();
    const [wasOpenedOnce, setWasOpenedOnce] = useState(false);

    useEffect(() => {
        if (isThreadsPanelOpen && !wasOpenedOnce) {
            setWasOpenedOnce(true);
        }
    }, [isThreadsPanelOpen]);
    return { isThreadsPanelOpen, setIsThreadsPanelOpen, wasOpenedOnce };
};

export const useStartNewSession = () => {
    const { setModelData, setModelSerialNumber } = useModel();
    const { setFirstRequestPanel } = useSwitchPanel();
    const { onClearAndBack } = useClickedClearAndBack();
    const { resetChatContext } = useResetChatContext();
    const { resetAskStreamContext } = useAskStreamContext();
    const { setSelectedThread } = useAppContext();
    const dispatch = useDispatch();

    const startNewSession = () => {
        setSelectedThread('');
        setFirstRequestPanel();
        setModelData(null);
        setModelSerialNumber(null);
        onClearAndBack();
        resetChatContext();
        resetAskStreamContext();
        dispatch(resetCoPilot());
        dispatch(setIsSmallChat(false));
        dispatch(resetState());
        dispatch(resetObservationInputs());
        dispatch(resetWorkOrderState());
    };
    return { startNewSession };
};

export const useEditedMessages = () => {
    const {
        indexAndHeightOfEditedEntireMessage,
        setIndexAndHeighOfEditedEntireMessage
    } = useAppContext();
    return {
        indexAndHeightOfEditedEntireMessage,
        setIndexAndHeighOfEditedEntireMessage
    };
};
export const useTenant = () => {
    const tenantId: string = useSelector(
        (state: any) => state.userProfileReducer.customerName
    );
    return { tenantId };
};

export const useSelectedMessage = () => {
    const { selectedMessage, setSelectedMessage } = useAppContext();
    return { selectedMessage, setSelectedMessage };
};

export const useDownloadSource = () => {
    const { downloadInProgressFileList, setDownloadInProgressFileList } =
        useAppContext();

    const getIsSourceDownloading = (filename: string, model: string) =>
        downloadInProgressFileList.has(filename + model);

    const setIsDownloading = (isDownloading: boolean, key: string) => {
        const newSet = new Set(downloadInProgressFileList);
        if (isDownloading) {
            newSet.add(key);
        } else {
            newSet.delete(key);
        }
        setDownloadInProgressFileList(newSet);
    };

    const downloadSource = async (
        url: string,
        filename: string,
        model: string
    ) => {
        if (getIsSourceDownloading(filename, model)) {
            return;
        }
        setIsDownloading(true, filename + model);
        try {
            if (!url) {
                const res = await Backend.getSourceUrl(model, filename, 1);
                url = res.data.source_url;
            }
            Util.downloadFileFromExternalUrl(url, filename, {
                onFinal: () => setIsDownloading(false, filename + model)
            });
        } catch (ex: any) {
            setIsDownloading(false, filename + model);
            console.error('Failed to download source: ', filename, ex.message);
            window.sentry.log(ex);
        }
    };
    return {
        downloadSource,
        getIsSourceDownloading,
        downloadInProgressFileList
    };
};

export const useUserData = () => {
    const { userData, setUserData } = useAppContext();
    const triageBaseUrl = useSelector(
        (state: any) => state.appSettingsReducer?.triageBaseUrl
    );
    const { accessToken, username } = useSelector(
        (state: any) => state.globalReducer
    );
    const { email } = useSelector((state: any) => state.userProfileReducer);
    const updateUserDataValue = async (key: string, value: any) => {
        if (userData[key] === value) {
            return;
        }
        const newUserData = userData.clone();
        newUserData[key] = value;
        setUserData(newUserData);
        try {
            await StarkBackend.updateUserData(
                triageBaseUrl,
                accessToken,
                username,
                {
                    [key]: value,
                    email: email || undefined
                }
            );
        } catch (ex: any) {
            console.error('Failed to update user data: ', ex.message);
            window.sentry.log(ex);
            setUserData(userData);
        }
    };

    const setResponseDepth = (value: string) =>
        updateUserDataValue('response_depth', value);

    return {
        userData,
        responseDepth: userData.responseDepth,
        setResponseDepth
    };
};
export const useServerlessIndex = () => {
    const { isServerlessIndex } = useAppContext();
    return { isServerlessIndex };
};

export const useFeedbackCategories = () => {
    const { feedbackCategories, setFeedbackCategories } = useAppContext();
    return { feedbackCategories, setFeedbackCategories };
};

export const useProductTypeCatalog = () => {
    const { productTypeCatalog } = useAppContext();
    return { productTypeCatalog };
};

export const useSummaryChatUserQuestion = () => {
    const { addNewThread } = useThreadsHandling();
    const updateChatSummary = async (
        question: string,
        modelName: string,
        chatId: string,
        newChat: SingleChatHistoryData
    ) => {
        try {
            const response = await Backend.updateChatSummary(
                question,
                modelName,
                chatId
            );
            addNewThread({
                ...newChat,
                summary: response.summary
            });
        } catch (error) {
            addNewThread(newChat);
        }
    };

    return { updateChatSummary };
};

export const useInvestigationDetails = () => {
    const dispatch = useDispatch();

    const setInvestigationDetails = (
        model: IntegratedModel,
        message: string
    ) => {
        if (!model?.productIds) {
            return;
        }
        const { productTypeId, manufacturerId, productId } = model.productIds;
        dispatch(
            setInvestigationDetailsAction({
                productTypeId,
                manufacturerId,
                productId,
                observation: message
            })
        );
    };
    return { setInvestigationDetails };
};
