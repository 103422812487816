import { t } from 'i18next';
import { MessageInfo, SourceDocument } from '../types';
import { remark } from 'remark';
import strip from 'strip-markdown';
import { ChatItem, SummaryDetails } from '../components/SummaryModal/types';
import { capitalizeFirstLetter } from './utils';

export const generateDetails = ({
    modelSerialNumber,
    productType,
    manufacturer,
    model
}: SummaryDetails) => {
    const header = t('summary-modal.details-header');
    let detailsStr = `${header}:\n`;
    const details = [];

    const addDetail = (keyTranslation: string, value: string) => {
        if (value) {
            const key = `${t(keyTranslation)}: `;
            detailsStr += `${key}${value}\n`;
            details.push({
                key: { text: key, bold: true },
                value: { text: value, bold: false }
            });
        }
    };
    modelSerialNumber && addDetail('model.serial-number', modelSerialNumber);
    addDetail('model.product-type', productType);
    addDetail('model.manufacturer', manufacturer);
    addDetail('model.model', model);

    detailsStr += '\n';

    return { details, detailsStr, header };
};

export const generateCoPilotChatHistory = (
    messages: Array<MessageInfo>,
    feedbacksMap: Object
) => {
    const header = t('summary-modal.chat-history-header');
    let coPilotChatHistoryStr = `${header}:\n`;
    const chatHistory: Array<ChatItem> = [];

    const chatMessages = messages.filter(
        (message) => message.sender !== 'UserMock'
    );

    const createChatItem = (
        userMessage: MessageInfo,
        aquantMessage: MessageInfo,
        feedbacksMap: Object
    ) => {
        const item: ChatItem = {
            question: `${t('summary-modal.question')}: ${userMessage.message}`,
            responseHeader: t('search.answer'),
            response: aquantMessage?.noResponse
                ? t('summary-modal.no-response')
                : removeExpertEditTagsAndFormatSourceIndexes(
                      aquantMessage.message
                  ),
            relatedSourcesHeader: '',
            relatedSources: []
        };

        const feedback = feedbacksMap?.[aquantMessage.rowID];
        if (feedback && feedback.type) {
            const isLike = feedback.type === 'Like';
            item.response += ` (${isLike ? '👍' : '👎'}${feedback.type})`;
        }

        if (
            aquantMessage.relatedSourceDocuments?.length &&
            !aquantMessage?.noResponse
        ) {
            const sourcesCount = aquantMessage.relatedSourceDocuments.length;
            item.relatedSourcesHeader = `${capitalizeFirstLetter(
                t('search.based-on')
            )} ${sourcesCount} source${sourcesCount > 1 ? 's' : ''}`;

            item.relatedSources = aquantMessage.relatedSourceDocuments;
        }

        return item;
    };

    const appendToHistoryStr = (item: ChatItem) => {
        coPilotChatHistoryStr += `${item.question}\n\n${
            item.responseHeader
        }\n${removeMarkdown(item.response)}\n\n`;
        if (item.relatedSourcesHeader) {
            coPilotChatHistoryStr += `${item.relatedSourcesHeader}\n`;
            item.relatedSources.forEach((source, index) => {
                coPilotChatHistoryStr += `${getScopKASourceFormatted(
                    source,
                    index + 1
                )}\n`;
            });
        }
        coPilotChatHistoryStr += '\n';
    };

    for (let i = 0; i < chatMessages.length; i += 2) {
        const userMessage = chatMessages[i];
        const aquantMessage = chatMessages[i + 1];
        if (!userMessage || !aquantMessage) {
            continue;
        }

        const item = createChatItem(userMessage, aquantMessage, feedbacksMap);
        chatHistory.push(item);
        appendToHistoryStr(item);
    }

    if (!chatHistory.length) {
        return { header: '', chatHistory, coPilotChatHistoryStr: '' };
    }

    return { header, chatHistory, coPilotChatHistoryStr };
};

const removeExpertEditTagsAndFormatSourceIndexes = (response: string) => {
    let formattedResponse = response.replace(/EXPERT_EDIT/g, '');
    formattedResponse = formattedResponse.replace(
        /SOURCE_(\d+)/g,
        (_, num) => `[${num}]`
    );
    return formattedResponse;
};

const getScopKASourceFormatted = (sourceDoc: SourceDocument, index: number) => {
    const {
        page = 0,
        start = 0,
        media_type = '',
        filename = ''
    } = sourceDoc?.metadata;
    let metaDataValue = '';
    if (media_type === 'video') {
        metaDataValue = getTimeStringFromSeconds(start);
    } else {
        metaDataValue = `page ${page}`;
    }
    return `${index}. ${filename}, ${metaDataValue}`;
};

const getTimeStringFromSeconds = (seconds: number) =>
    new Date(seconds * 1000).toISOString().slice(seconds >= 3600 ? 11 : 14, 19);

export const removeMarkdown = (markdownString: string) => {
    const result = remark()
        .use(strip, {
            keep: ['list', 'listItem']
        })
        .processSync(markdownString);
    return result.toString();
};
